/* eslint-disable react/prop-types */
import React from 'react';
export default function UserIcon({
  width = 30,
  height = 30,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={(height as number) + 1} viewBox={`0 0 ${width} ${(height as number) + 1}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="UserIcon" data-sentry-source-file="userIcon.tsx">
      <g clipPath="url(#clip0_10327_6904)" data-sentry-element="g" data-sentry-source-file="userIcon.tsx">
        <path d="M15 4.3457C15.9271 4.3457 16.8334 4.62062 17.6042 5.13569C18.3751 5.65076 18.9759 6.38285 19.3307 7.23937C19.6855 8.0959 19.7783 9.0384 19.5974 9.94769C19.4166 10.857 18.9701 11.6922 18.3146 12.3478C17.659 13.0033 16.8238 13.4498 15.9145 13.6306C15.0052 13.8115 14.0627 13.7187 13.2062 13.3639C12.3496 13.0091 11.6176 12.4083 11.1025 11.6374C10.5874 10.8666 10.3125 9.9603 10.3125 9.0332C10.3125 7.79 10.8064 6.59772 11.6854 5.71864C12.5645 4.83956 13.7568 4.3457 15 4.3457ZM15 2.4707C13.7021 2.4707 12.4333 2.85559 11.3541 3.57668C10.2749 4.29778 9.43374 5.3227 8.93704 6.52184C8.44034 7.72098 8.31038 9.04048 8.5636 10.3135C8.81682 11.5865 9.44183 12.7558 10.3596 13.6736C11.2774 14.5914 12.4467 15.2164 13.7197 15.4696C14.9927 15.7228 16.3122 15.5929 17.5114 15.0962C18.7105 14.5995 19.7354 13.7583 20.4565 12.6791C21.1776 11.5999 21.5625 10.3311 21.5625 9.0332C21.5625 7.29272 20.8711 5.62352 19.6404 4.39281C18.4097 3.16211 16.7405 2.4707 15 2.4707Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="userIcon.tsx" />
        <path d="M24.375 28.7207H22.5V24.0332C22.5 23.4176 22.3788 22.8081 22.1432 22.2394C21.9076 21.6707 21.5623 21.1539 21.1271 20.7186C20.6918 20.2834 20.175 19.9381 19.6063 19.7025C19.0376 19.4669 18.4281 19.3457 17.8125 19.3457H12.1875C10.9443 19.3457 9.75201 19.8396 8.87294 20.7186C7.99386 21.5977 7.5 22.79 7.5 24.0332V28.7207H5.625V24.0332C5.625 22.2927 6.3164 20.6235 7.54711 19.3928C8.77782 18.1621 10.447 17.4707 12.1875 17.4707H17.8125C19.553 17.4707 21.2222 18.1621 22.4529 19.3928C23.6836 20.6235 24.375 22.2927 24.375 24.0332V28.7207Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="userIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="userIcon.tsx">
        <clipPath id="clip0_10327_6904" data-sentry-element="clipPath" data-sentry-source-file="userIcon.tsx">
          <rect width={width} height={height} fill="currentColor" transform="translate(0 0.595703)" data-sentry-element="rect" data-sentry-source-file="userIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
}