import CampaignPreviewCard from 'components/cards/campaignPreview';
import Grid from 'components/uiKit/grid';
import AuthContext from 'context/auth';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import CampaignsApi, { campaignsI } from 'utils/services/api/requests/campaigns';
export default function CampaignSection() {
  const {
    token
  } = useContext(AuthContext);
  const {
    data
  } = useQuery(['campaigns', token], CampaignsApi.getItems, {});
  return <Grid gap="1rem" padding={['pb-3']} data-sentry-element="Grid" data-sentry-component="CampaignSection" data-sentry-source-file="index.tsx">
      {data?.map((card: campaignsI, index: number) => {
      return <CampaignPreviewCard title={card.titlePreview} description={card.descriptionPreview} externalName={card.externalName} imageUrl={card.imagePreview} key={`CampaignSection-${card.externalName}-${index}`} />;
    })}
    </Grid>;
}