import Typography from 'components/uiKit/typography';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './input.group.module.scss';
import { formatDateToFinnish } from '../../format';
function Feedback({
  feedback
}: {
  feedback: ReactNode;
}) {
  return <div className={styles.feedback} data-sentry-component="Feedback" data-sentry-source-file="index.tsx">
      <Typography size="xsm" data-sentry-element="Typography" data-sentry-source-file="index.tsx">{feedback}</Typography>
    </div>;
}
function Label({
  label
}: {
  label: string | ReactNode;
}) {
  return typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="sm" /> : <>{label}</>;
}
export function InputFieldDate({
  onChange = () => {},
  inputProps,
  value,
  name,
  id,
  label,
  readOnly,
  onBlur,
  feedback,
  status,
  fontSize,
  stylesProps
}: any) {
  const [currentDate, setCurrentDate] = useState(value || null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  function update(val: string, e: any) {
    setCurrentDate(val);
    onChange(e);
  }
  const handleTextInputInteraction = () => {
    try {
      if (dateInputRef.current && !inputProps?.readOnly) {
        dateInputRef.current.focus();
        dateInputRef.current.click();
        dateInputRef.current.showPicker();
      }
    } catch {
      // the error type is NotAllowedError: showpicker () requires a user gesture
      // not to do anything for this error
    }
  };
  useEffect(() => {
    if (!currentDate) {
      setCurrentDate(value);
    }
  }, [value]);
  return <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
    ...stylesProps
  }} data-sentry-component="InputFieldDate" data-sentry-source-file="index.tsx">
      <label data-filled={!!value} style={{
      position: 'relative'
    }}>
        <input value={formatDateToFinnish(currentDate)} {...inputProps} type="text" placeholder={label} readOnly={inputProps?.readOnly} className={styles['input-view']} onFocus={handleTextInputInteraction} onClick={handleTextInputInteraction} onChange={() => {}} />
        <div data-ready-only={readOnly} id="animated-label-value" className={styles['animated-label']}>
          <Label label={label} data-sentry-element="Label" data-sentry-source-file="index.tsx" />
        </div>

        <input ref={dateInputRef} value={currentDate || ''} onChange={e => update(e.target.value, e)} onBlur={onBlur} disabled={inputProps?.readOnly} name={name} id={id} {...inputProps} type="date" className={styles['input-none']} lang="fi" />
      </label>
      {feedback && <Feedback feedback={feedback} />}
    </div>;
}