import { AxiosError } from 'axios';
import { api } from '../..';
import urls from '../urls';
export type typeFrequency = 'DAY' | 'WEEK' | 'MONTH' | '';
export interface DepositLimitInterface {
  applyAfter?: any;
  created?: string;
  frequency: typeFrequency;
  futureFrequency?: typeFrequency | null;
  futureLimitAmount?: number | string | null;
  limitAmount: number | string | null;
  totalAmount?: number | string | null;
  updated?: string;
}
interface submitFormOptions {
  successCallBack?: Function;
  errorCallBack?: Function;
  silent?: boolean;
}
async function submitForm(form: DepositLimitInterface, options?: submitFormOptions) {
  const {
    errorCallBack,
    successCallBack,
    silent
  } = options || {};
  const action = form?.created ? api.put : api.post;
  const url = form?.created ? urls.depositLimit.update : urls.depositLimit.create;
  const response = await action(url, {
    frequency: form?.frequency,
    limitAmount: form?.limitAmount
  });
  return response.data;
}
async function getDepositLimit() {
  try {
    const res = await api.get(urls.depositLimit.get);
    return res.data;
  } catch (error: AxiosError | any) {
    if (error.response.status === 404) {
      return false;
    }
    return error;
  }
}
const DepositLimitApi = {
  submitForm,
  getDepositLimit
};
export default DepositLimitApi;