import Grid from 'components/uiKit/grid';
import styles from './styles.module.scss';
export function SizePage({
  size,
  setSize
}: any) {
  return <Grid width="3.5rem" verticalAlgin="center" className={styles.content} data-sentry-element="Grid" data-sentry-component="SizePage" data-sentry-source-file="sizePage.tsx">
      <input type="number" name="page" id="page" value={size} onChange={({
      target
    }) => {
      setSize(parseInt(target.value, 10));
    }} />
    </Grid>;
}