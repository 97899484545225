import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { createContext, useContext } from 'react';
import Card from 'components/uiKit/card';
import AuthContext from 'context/auth';
import { useQuery } from 'react-query';
import FreeSpinsApi from 'utils/services/api/requests/freeSpins';
import Loading from 'assets/loading';
import FreeSpinsList from './list';
interface FreeSpinsContextI {
  freeSpinsList: Array<any>;
  freeSpinsListFilter: Array<any>;
}
export const FreeSpinsContext = createContext<FreeSpinsContextI>({
  freeSpinsList: [],
  freeSpinsListFilter: []
});
export default function FreeSpinBonuses() {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    data: freeSpinsList,
    isLoading: isLoadingAccepted,
    refetch: refreshAccepted
  } = useQuery(['freeBetsAccepted', token], FreeSpinsApi.getAcceptedItems, {
    enabled: !!isAuthenticated
  });
  if (isLoadingAccepted) {
    return <Grid verticalAlgin="center" horizontalAlgin="center">
        <Loading />
      </Grid>;
  }
  const list = [...(freeSpinsList?.map((item: any) => ({
    ...item,
    FEstatus: 'accepted'
  })) || [])].filter((item: any) => item?.betCount || item?.bonusTemplate?.betCount);
  return <FreeSpinsContext.Provider value={{
    freeSpinsList,
    freeSpinsListFilter: list
  }} data-sentry-element="unknown" data-sentry-component="FreeSpinBonuses" data-sentry-source-file="index.tsx">
      <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Card color="white" defaultValue="collapse" style={{
        padding: '1.2rem'
      }} hidden={list.length <= 0} data-sentry-element="Card" data-sentry-source-file="index.tsx">
          <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Typography translateGroup="free-spin-bonuses-section" translateKey="ILMAISPYÖRÄYTYKSET" weight={600} algin="center" color="var(--primary)" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
          </Grid>
          <Grid gap="1rem" padding={['pt-3']} horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <FreeSpinsList data-sentry-element="FreeSpinsList" data-sentry-source-file="index.tsx" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </FreeSpinsContext.Provider>;
}