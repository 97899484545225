import Grid from 'components/uiKit/grid';
import React from 'react';
import ProgressXP from './progress';
import Disclaimer from './disclaimer';
export default function XpBonuses() {
  return <Grid gap="2rem" style={{
    color: '#fff'
  }} data-sentry-element="Grid" data-sentry-component="XpBonuses" data-sentry-source-file="index.tsx">
      <ProgressXP data-sentry-element="ProgressXP" data-sentry-source-file="index.tsx" />
      <Disclaimer data-sentry-element="Disclaimer" data-sentry-source-file="index.tsx" />
    {/* <NextLevels {...xpProgress} /> */}
    </Grid>;
}