/* eslint-disable react/prop-types */
import React from 'react';
export default function WheelIcon({
  width = 27,
  height = 30,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="WheelIcon" data-sentry-source-file="wheelIcon.tsx">
      <g clipPath="url(#clip0_10327_6910)" data-sentry-element="g" data-sentry-source-file="wheelIcon.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.885 7.15963C13.2247 7.64495 13.9495 7.64495 14.2892 7.15963L18.3708 1.32783C18.7633 0.766667 18.3578 0 17.6685 0H9.50536C8.81604 0 8.41053 0.766667 8.80337 1.32783L12.885 7.15963ZM6.05566 16.619C6.05566 20.6945 9.38862 23.9982 13.4998 23.9982C17.6111 23.9982 20.944 20.6945 20.944 16.619C20.944 12.5434 17.6111 9.23976 13.4998 9.23976C9.38862 9.23976 6.05566 12.5434 6.05566 16.619ZM19.1515 16.619C19.1515 19.7144 16.62 22.2235 13.4974 22.2235C10.3747 22.2235 7.8432 19.7144 7.8432 16.619C7.8432 13.5235 10.3747 11.0144 13.4974 11.0144C16.62 11.0144 19.1515 13.5235 19.1515 16.619ZM18.4533 4.17829C18.0126 4.80795 17.5669 5.44495 17.1407 6.05352C21.5762 7.55535 24.7705 11.7128 24.7705 16.6183C24.7705 22.7884 19.7244 27.7902 13.5002 27.7902C7.27587 27.7902 2.2298 22.7884 2.2298 16.6183C2.2298 11.6569 5.49543 7.45627 10.0103 5.99908C9.58349 5.3893 9.13754 4.75199 8.69775 4.12355C3.61647 6.04404 0 10.9064 0 16.618C0 24.0086 6.04423 30 13.5002 30C20.9561 30 27.0003 24.0086 27.0003 16.618C27.0003 10.9627 23.4561 6.13731 18.4536 4.17829H18.4533Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="wheelIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="wheelIcon.tsx">
      <clipPath id="clip0_10327_6910" data-sentry-element="clipPath" data-sentry-source-file="wheelIcon.tsx">
        <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="wheelIcon.tsx" />
      </clipPath>
      </defs>
    </svg>;
}