import { buttonColors } from 'components/uiKit/buttons/types';
import React, { useEffect, useState } from 'react';
import { gridBaseProps } from 'components/uiKit/grid/types';
import Grid from 'components/uiKit/grid';
import Collapse from 'assets/icons/collapse';
import Expand from 'assets/icons/expand';
import Card from '../card';
import styles from './style.module.scss';
interface Props extends Omit<gridBaseProps, 'label' | 'value' | 'children'> {
  color?: buttonColors | 'root' | 'section' | 'transparent';
  children: React.ReactNode;
  header: React.ReactElement;
  defaultValue?: 'expand' | 'collapse';
  value?: 'expand' | 'collapse';
  maxHeight?: number;
}
export default function ExpandCollapseCard(props: Props) {
  const {
    children,
    header,
    defaultValue,
    value,
    maxHeight,
    onClick,
    ...cardProps
  } = props;
  const [state, setState] = useState<'expand' | 'collapse'>(defaultValue || 'collapse');
  function handlerState(e?: any) {
    if (onClick) {
      return onClick(e);
    }
    return setState(current => current === 'expand' ? 'collapse' : 'expand');
  }
  useEffect(() => {
    handlerState();
  }, [value]);
  return <Card {...cardProps} className={styles.wrapper} gap="0" data-sentry-element="Card" data-sentry-component="ExpandCollapseCard" data-sentry-source-file="index.tsx">
      <Grid wrap="nowrap" verticalAlgin="stretch" style={{
      cursor: 'pointer',
      height: 'fit-content'
    }} onClick={e => handlerState(e)} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid height="fit-content" verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">{header}</Grid>
        <Grid width="fit-content" verticalAlgin="center" className={styles.icon} data-state={state} style={{
        color: props?.color === 'primary' ? '#fff' : 'unset'
      }} onClick={e => handlerState(e)} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {state === 'expand' ? <Collapse height="23px" width="23px" onClick={e => handlerState(e)} /> : <Expand height="23px" width="23px" onClick={e => handlerState(e)} />}
        </Grid>
      </Grid>
      <Grid className={styles['content-box']} style={{
      maxHeight: state === 'collapse' ? '0px' : `${maxHeight || 1000}px`,
      overflow: 'hidden'
    }} padding={state === 'expand' ? ['pt-1'] : []} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {children}
      </Grid>
    </Card>;
}