import AuthContext from 'context/auth';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { getXpBonusesProgress } from 'utils/services/api/requests/bonuses';
import CampaignsApi from 'utils/services/api/requests/campaigns';
import FreeSpinsApi from 'utils/services/api/requests/freeSpins';
import MissionsApi from 'utils/services/api/requests/missions';
import { getProfile } from 'utils/services/api/requests/userEnv';

// component only used to improve the load time for sections of the menu
export default function PrefetchCall() {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    data,
    isLoading,
    error
  } = useQuery(['campaigns', token], CampaignsApi.getItems, {});
  const {
    data: freeSpinsActive,
    isLoading: isLoadingActive,
    refetch: refreshActive
  } = useQuery(['freeBetsActive', token], FreeSpinsApi.getActiveItems, {
    enabled: !!isAuthenticated
  });
  const {
    data: freeSpinsList,
    isLoading: isLoadingAvailable,
    refetch: refreshAvailable
  } = useQuery(['freeBetsAvailable', token], FreeSpinsApi.getAvailableItems, {
    enabled: !!isAuthenticated
  });
  const {
    data: activeMissions,
    isLoading: activeMissionLoading
  } = useQuery(['user-active-mission', token], MissionsApi.getActiveItems, {
    enabled: !!isAuthenticated
  });
  const {
    data: profile
  } = useQuery(['profile', token], getProfile, {
    staleTime: 12000,
    enabled: !!isAuthenticated
  });
  const {
    data: missionList
  } = useQuery(['all-missions', token], MissionsApi.getAvailableItems, {
    enabled: !!isAuthenticated && !activeMissionLoading
  });
  const {
    data: HistoryMissionList
  } = useQuery(['history-missions', token], MissionsApi.getHistory, {
    enabled: !!isAuthenticated
  });
  const {
    data: xpProgress
  } = useQuery(['xp-bonus-progress', token], getXpBonusesProgress, {
    enabled: !!isAuthenticated
  });
  return <>
        </>;
}