import BackArrow from 'assets/icons/backArrow';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import { useState } from 'react';
import BonusesSection from 'sections/bonuses';
import CampaignSection from 'sections/campaigns';
import HistorySection from 'sections/history';
import HamburgerMenu from 'sections/Menu';
import LimitsSection from 'sections/limits';
import ProfileForm from 'sections/profileForm';
import { UiCloseButton } from 'components/uiKit/buttons/CloseButton';
import { twMerge } from 'tailwind-merge';
import styles from './styles.module.scss';
interface Props {
  closeDialog: () => void;
  menu?: 'menu' | 'rewards' | 'campaigns' | string;
}
function FloatMenu({
  closeDialog,
  menu
}: Props) {
  const [section, setSection] = useState<string>(menu || 'menu');
  const [stateProfile, setStateProfile] = useState('PROFILE');
  const renderTitle = () => {
    switch (section) {
      case 'menu-profile':
        return <Typography translateGroup="float-menu-title" translateKey="TILINI" weight={600} />;
      case 'rewards':
        return <Typography translateGroup="float-menu-title" translateKey="PALKINNOT" weight={600} />;
      case 'campaigns':
        return <Typography translateGroup="float-menu-title" translateKey="KAMPANJAT" weight={600} />;
      default:
        return '';
    }
  };
  const renderSection = () => {
    switch (section) {
      case 'menu':
        return <HamburgerMenu section={section} setSection={setSection} />;
      case 'menu-profile':
        return <Grid gap="1rem" padding={['pt-3']}>
            <ProfileForm state={stateProfile === 'PROFILE' ? 'expand' : 'collapse'} handleState={() => setStateProfile(stateProfile === 'PROFILE' ? '' : 'PROFILE')} />
            <HistorySection state={stateProfile === 'HISTORY' ? 'expand' : 'collapse'} handleState={() => setStateProfile(stateProfile === 'HISTORY' ? '' : 'HISTORY')} />
            <LimitsSection />
          </Grid>;
      case 'rewards':
        return <BonusesSection />;
      case 'campaigns':
        return <CampaignSection />;
      default:
        return '';
    }
  };
  const titleBar = () => {
    return <Grid wrap="nowrap" style={{
      color: '#fff',
      position: 'relative'
    }} padding={['pb-3']} verticalAlgin="stretch" id="dt-menu-title" data-sentry-element="Grid" data-sentry-component="titleBar" data-sentry-source-file="index.tsx">
        <Grid verticalAlgin="center" style={{
        position: 'absolute',
        left: '0rem'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {section !== 'menu' && <BackArrow onClick={() => setSection('menu')} style={{
          cursor: 'pointer'
        }} />}
        </Grid>
        <Grid horizontalAlgin="center" verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {renderTitle()}
        </Grid>
      </Grid>;
  };
  return <Grid className={styles.wrapper} id="dt-float-menu" width="fit-content" data-sentry-element="Grid" data-sentry-component="FloatMenu" data-sentry-source-file="index.tsx">
      <Grid width="380px" className={twMerge(styles['content-wrapper'], 'pl-10 pr-7 py-16')} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <UiCloseButton onClick={() => closeDialog()} variant="light" className="absolute top-0 right-0 w-[4rem] h-[4rem]" data-sentry-element="UiCloseButton" data-sentry-source-file="index.tsx" />
        <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {titleBar()}
        </Grid>
        <Grid className={styles['section-wrapper']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {renderSection()}
        </Grid>
      </Grid>
    </Grid>;
}
export default FloatMenu;