import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { getWageringRequirements, WageringResponse } from 'utils/services/api/requests/wallet';
import AuthContext from 'context/auth';
export const useWithdrawalCheck = () => {
  const router = useRouter();
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    query
  } = router;
  const {
    data: wageringData,
    isLoading
  } = useQuery<WageringResponse | null>(['wagering-requirements', token], getWageringRequirements, {
    enabled: !!isAuthenticated
  });
  const handleWithdrawalClick = async () => {
    if (isLoading) {
      return;
    }
    if (wageringData?.remainingWager && wageringData.remainingWager > 0) {
      // Redirect with withdrawal-blocked state
      await router.push({
        pathname: '/',
        query: {
          ...query,
          type: 'withdrawal',
          state: 'wagering-blocked'
        }
      }, undefined, {
        shallow: true
      });
      return;
    }

    // If wagering requirements are met, proceed to withdrawal page
    await router.push('/cashier/withdraw');
  };
  return {
    handleWithdrawalClick,
    isLoading,
    remainingWager: wageringData?.remainingWager || 0,
    error: wageringData?.error
  };
};