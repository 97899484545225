import Typography from 'components/uiKit/typography';
import { DepositLimitInterface } from '../../types';
export const errorMessages = () => ({
  frequency: {
    required: <Typography translateGroup="form-validate-messages" translateKey="required" />
  },
  limitAmount: {
    required: <Typography translateGroup="form-validate-messages" translateKey="required" />
  }
});
function validateForm(data: DepositLimitInterface) {
  const erros: any = {};
  if (!data.frequency) {
    erros.frequency = errorMessages().frequency.required;
  }
  if (!data.limitAmount) {
    erros.limitAmount = errorMessages().limitAmount.required;
  }
  return {
    ...erros,
    count: Object.keys(erros).length
  };
}
export default validateForm;