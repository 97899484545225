import { api } from '../..'
import urls from '../urls'

async function getAvailableItems() {
    const res = await api.get(urls.missions.getAllMissions)
    return res.data
}
async function getAcceptedItems() {
    const res = await api.get(urls.missions.getAcceptedMissions)
    return res.data
}
async function getActiveItems() {
    const res = await api.get(urls.missions.getActiveMissions)
    return res.data
}

async function getHistory() {
    const res = await api.get(urls.missions.getHistory)
    return res.data
}
const MissionsApi = {
    getAvailableItems,
    getAcceptedItems,
    getActiveItems,
    getHistory,

}

export default MissionsApi
