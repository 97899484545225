import React, { useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'components/customLink';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import { staticLobbyItem } from 'pages/api/static-lobbies';
import Image from 'next/image';
import Loading from 'assets/loading';
import styles from './styles.module.scss';
const GameProvider = dynamic(() => import('../default/components/GameProvider'), {
  loading: () => <Loading />
});
const GameName = dynamic(() => import('../default/components/GameName'), {
  loading: () => <Loading />
});
export default function LargePromoGameCard(props: staticLobbyItem) {
  const {
    provider,
    name,
    displayProvider,
    displayProviderOnHover,
    displayName,
    displayNameOnHover,
    aspectRatio,
    backgroundColor,
    link,
    linkLabel,
    thumbnail,
    onHoverThumbnail,
    displayNameColor,
    displayNameOnHoverColor,
    displayProviderColor,
    displayProviderOnHoverColor,
    scaleThumbOnHover,
    changeThumbOnHover,
    displayCta,
    displayCtaOnHover,
    ctaBackgroundColor,
    ctaBackgroundColorOnHover,
    ctaColor,
    ctaColorOnHover,
    customLink
  } = props;
  const {
    state,
    appType
  } = useContext(GlobalContext);
  const [mouseIn, setMouseIn] = useState(false);
  const handleMouseEnter = () => {
    if (!mouseIn) setMouseIn(true);
  };
  const handleMouseLeave = () => {
    if (mouseIn) setMouseIn(false);
  };
  return <Link href={link || customLink || ''} style={{
    width: '100%'
  }} data-sentry-element="Link" data-sentry-component="LargePromoGameCard" data-sentry-source-file="index.tsx">
      <Grid verticalAlgin="stretch" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.wrapper} style={{
      aspectRatio: aspectRatio || '410 / 248',
      backgroundColor
    }} padding={['p-4']} data-scale-on-hover={!!scaleThumbOnHover} wrap="nowrap" gap="1rem" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid width="calc(55% - 1rem)" verticalAlgin="center" className={styles.label} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <GameProvider provider={provider} visible={mouseIn ? !!displayProviderOnHover : !!displayProvider} displayProviderColor={mouseIn ? displayProviderOnHoverColor : displayProviderColor} data-sentry-element="GameProvider" data-sentry-source-file="index.tsx" />
          <GameName styleProps={{
          fontSize: appType === 'mobile' ? '1.5rem' : '2rem'
        }} name={name} visible={mouseIn ? !!displayNameOnHover : !!displayName} displayNameColor={mouseIn ? displayNameOnHoverColor : displayNameColor} data-sentry-element="GameName" data-sentry-source-file="index.tsx" />
        </Grid>
        <Grid data-change-on-hover={!!(changeThumbOnHover && onHoverThumbnail)} width="45%" verticalAlgin="center" className={styles.graph} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <div data-img-type="thumb" className={styles['wrapper-thumb']} style={{
          backgroundColor
        }}>
            <Image src={thumbnail} alt={name} fill sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" style={{
            objectFit: 'cover'
          }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          </div>
          {changeThumbOnHover && onHoverThumbnail && <div data-img-type="thumb-hover" className={styles['wrapper-thumb']} style={{
          backgroundColor
        }}>
              <Image src={onHoverThumbnail} alt={name} priority={mouseIn} fill sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" style={{
            opacity: mouseIn ? 1 : 0,
            transition: 'all ease-in-out 300ms',
            objectFit: 'cover'
          }} />
            </div>}
        </Grid>
        {linkLabel && <div data-display-cta={!!displayCta} data-display-cta-hover={!!displayCtaOnHover} className={styles.cta} hidden={appType === 'mobile'}>
            <div>
              <button id={`${name}-cta`} data-btn-state="cta" type="button" style={{
            color: ctaColor,
            backgroundColor: ctaBackgroundColor,
            borderColor: ctaBackgroundColor
          }}>
                <Typography size="md" weight={500}>
                  {linkLabel}
                </Typography>
              </button>
              <button data-btn-state="cta-hover" type="button" style={{
            color: ctaColorOnHover,
            backgroundColor: ctaBackgroundColorOnHover,
            borderColor: ctaBackgroundColorOnHover
          }}>
                <Typography size="md" weight={500}>
                  {linkLabel}
                </Typography>
              </button>
            </div>
          </div>}
      </Grid>
    </Link>;
}