/* eslint-disable react/prop-types */
import React from 'react';
export default function BullHornIcon({
  width = 30,
  height = 30,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="BullHornIcon" data-sentry-source-file="bullhornIcon.tsx">
      <g clipPath="url(#clip0_10327_6917)" data-sentry-element="g" data-sentry-source-file="bullhornIcon.tsx">
        <path d="M24.3753 5.625V7.65938L5.28775 11.1281C4.85095 11.208 4.45668 11.4403 4.17512 11.7836C3.89357 12.127 3.74301 12.5591 3.75025 13.0031V17.0719C3.74301 17.5159 3.89357 17.948 4.17512 18.2914C4.45668 18.6347 4.85095 18.867 5.28775 18.9469L7.50025 19.275V22.5C7.50025 22.9973 7.69779 23.4742 8.04942 23.8258C8.40106 24.1775 8.87797 24.375 9.37525 24.375H16.8752C17.3725 24.375 17.8494 24.1775 18.2011 23.8258C18.5527 23.4742 18.7503 22.9973 18.7503 22.5V21.3187L24.3753 22.3406V24.375H26.2503V5.625H24.3753ZM16.8752 22.5H9.37525V19.6219L16.8752 20.9812V22.5ZM5.62525 17.0344V12.9656L24.3753 9.5625V20.4375L5.62525 17.0344Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="bullhornIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="bullhornIcon.tsx">
        <clipPath id="clip0_10327_6917" data-sentry-element="clipPath" data-sentry-source-file="bullhornIcon.tsx">
        <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="bullhornIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
}