import { staticLobbyItem } from 'pages/api/static-lobbies'

export function splitArrayIntoGroups(arr: Array<any>, groupSize: number) {
    const result: Array<any> = []
    for (let i = 0; i < arr.length; i += groupSize) {
        result.push(arr.slice(i, i + groupSize))
    }
    return result
}
interface OrderedListI {
    data: Array<any>,
    key: string,
    order: 'asc' | 'desc',
}
export function orderList({ data, key, order = 'asc' }: OrderedListI) {
    if (!Array.isArray(data) || typeof key !== 'string') {
        throw new Error('Invalid input parameters')
    }

    return data.sort((a, b) => {
        if (a[key] < b[key]) return order === 'asc' ? -1 : 1
        if (a[key] > b[key]) return order === 'asc' ? 1 : -1
        return 0
    })
}

export function moveSizeTwoItemsUp(items: staticLobbyItem[]): staticLobbyItem[] {
    let i = 1 // Start from 1 since we can't move the first item up
    while (i < items.length) {
        if (items[i].size === 2) {
            // Swap with the item before it
            [items[i - 1], items[i]] = [items[i], items[i - 1]]
            i += 2 // Move two positions forward to avoid double-swapping
        } else {
            i += 1 // Only increment i if no swapping occurred
        }
    }
    return items
}
