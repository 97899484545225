import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import moment from 'moment';
import React from 'react';
import { formatCurrency } from 'utils/functions/wallet';
interface Props {
  date: string;
  type: string;
  gameName: string;
  status: string;
  amount: string | number;
}
export default function MobileHistoryCard(props: Props) {
  const {
    date,
    type,
    gameName,
    status,
    amount
  } = props;
  return <Grid padding={['p-3']} gap="0.5rem" style={{
    background: '#EFEEEE',
    color: '#000',
    borderRadius: '8pt'
  }} data-sentry-element="Grid" data-sentry-component="MobileHistoryCard" data-sentry-source-file="mobileHistoryCard.tsx">
      <Grid wrap="nowrap" horizontalAlgin="space-between" data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
        <Grid gap="0.5rem" data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
          <Typography data-sentry-element="Typography" data-sentry-source-file="mobileHistoryCard.tsx">{moment(date).format('DD/MM/YYYY')}</Typography>
          <Typography weight={600} data-sentry-element="Typography" data-sentry-source-file="mobileHistoryCard.tsx">{moment(date).format('HH:mm')}</Typography>
        </Grid>

        <Grid data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
          <Typography weight={600} algin="right" color={['GAME_SPIN'].includes(type) ? 'var(--danger)' : ['GAME_WIN'].includes(type) ? 'var(--success)' : '#000'} data-sentry-element="Typography" data-sentry-source-file="mobileHistoryCard.tsx">
            {formatCurrency(amount as any, 'EUR')}
          </Typography>
        </Grid>
      </Grid>
      <Grid wrap="nowrap" horizontalAlgin="space-between" data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
          <Typography weight={600} data-sentry-element="Typography" data-sentry-source-file="mobileHistoryCard.tsx">{type}</Typography>
        </Grid>
      </Grid>
      <Grid wrap="nowrap" horizontalAlgin="space-between" data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="mobileHistoryCard.tsx">
          <Typography data-sentry-element="Typography" data-sentry-source-file="mobileHistoryCard.tsx">{`${gameName || ''}`}</Typography>
        </Grid>
      </Grid>
    </Grid>;
}