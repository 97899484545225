import Grid from 'components/uiKit/grid';
import Container from 'components/uiKit/grid/container';
import InputGroupNew from 'components/uiKit/inputs/inputNew';
import SelectGroup from 'components/uiKit/inputs/selectGroup';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import React, { useContext } from 'react';
import Loading from 'assets/loading';
import { DataTable } from 'components/uiKit/dataTable';
import { columns } from './listSettings';
import { ListMobileHistory } from './mobile';
import styles from './styles.module.scss';
import { useHistoryHook } from './useHistoryHook';
export default function History() {
  const {
    appType
  } = useContext(GlobalContext);
  const {
    dateFilter,
    sanitizedFilter,
    uniqueTypesSet,
    handlerChange,
    isLoading
  } = useHistoryHook();
  const optionsType = Array.from(uniqueTypesSet).map(type => ({
    value: type,
    label: <Typography translateGroup="history" translateKey={type} key={type} />
  }));
  optionsType.unshift({
    value: 'all',
    label: <Typography translateGroup="history" translateKey="Kaikki tapahtumat" />
  });
  if (isLoading) {
    return <Grid verticalAlgin="center" horizontalAlgin="center">
        <Loading />
      </Grid>;
  }
  return <Grid className={styles.wrapper} data-sentry-element="Grid" data-sentry-component="History" data-sentry-source-file="index.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <Grid horizontalAlgin="center" gap="1rem" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid gap={appType === 'mobile' ? '3rem' : '1rem'} horizontalAlgin="center" padding={appType === 'mobile' ? ['pe-2', 'ps-2'] : 'p-0'} style={{
          position: 'relative',
          zIndex: '2',
          maxWidth: '55.3rem'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid padding={['pt-2']} responsiveWidth={{
            sm: 100,
            md: 'calc(33% - (2rem / 3))'
          }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <InputGroupNew id="startDate" name="startDate" label="Alkamispäivämäärä" value={dateFilter?.startDate} inputType="date" inputProps={{
              readOnly: false,
              lang: 'fi'
            }} onChange={event => handlerChange(event)} data-sentry-element="InputGroupNew" data-sentry-source-file="index.tsx" />
            </Grid>
            <Grid padding={['pt-2']} responsiveWidth={{
            sm: 100,
            md: 'calc(33% - (2rem / 3))'
          }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <InputGroupNew id="endDate" name="endDate" label="Loppumispäivämäärä" inputType="date" value={dateFilter?.endDate} inputProps={{
              lang: 'fi',
              readOnly: false
            }} onChange={event => handlerChange(event)} data-sentry-element="InputGroupNew" data-sentry-source-file="index.tsx" />
            </Grid>
            <Grid padding={['pt-3']} responsiveWidth={{
            sm: 100,
            md: 'calc(33% - (2rem / 3))'
          }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <SelectGroup id="mainTypeFilter" name="mainTypeFilter" label="Tapahtuma" isSearchable={false} onChange={event => handlerChange(event)} options={optionsType} value={optionsType?.filter(item => item.value === dateFilter?.mainTypeFilter)} data-sentry-element="SelectGroup" data-sentry-source-file="index.tsx" />
            </Grid>
          </Grid>
        </Grid>

        <Grid margin="mt-3" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {appType === 'mobile' ? <ListMobileHistory sanitizedFilter={sanitizedFilter} /> : <Grid horizontalAlgin="center">
              <Grid style={{
            maxWidth: '55.3rem'
          }}>
                <DataTable data={sanitizedFilter} columns={columns()} />
              </Grid>
            </Grid>}
        </Grid>
      </Container>
    </Grid>;
}