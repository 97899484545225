import React, { FocusEventHandler } from 'react';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import { BsInfoCircle } from 'react-icons/bs';
import styles from './styles.module.scss';
interface OCI {
  target: {
    name: string;
    id: string;
    value: any;
  };
}
interface Props {
  id: string;
  name: string;
  value: boolean;
  onChange: (item: OCI) => void;
  label: string | undefined | any;
}
export default function Toggle(props: Props) {
  const {
    value,
    onChange,
    id,
    name,
    label
  } = props;
  return <Grid wrap="nowrap" className={styles.wrapper} horizontalAlgin="space-between" verticalAlgin="center" data-sentry-element="Grid" data-sentry-component="Toggle" data-sentry-source-file="index.tsx">
      <Grid gap="0.5rem" className={styles.label} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="normal" /> : label}
      </Grid>
      <Grid horizontalAlgin="flex-end" height="fit-content" className={styles['toggle-wrapper']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <div data-active={!!value} onClick={() => onChange({
        target: {
          id,
          name,
          value: !value
        }
      })} className={styles.toggle}>
          <div className={styles.switch} />
        </div>
      </Grid>
    </Grid>;
}