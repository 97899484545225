import Button from 'components/uiKit/buttons';
import Card from 'components/uiKit/card';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import AuthContext from 'context/auth';
import React, { useContext } from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import { toastSuccess } from 'utils/functions/notifications';
import { postSelfExclusionOption } from 'utils/services/api/requests/userEnv';
interface Props {
  blockOption: string;
  closeDialog: () => void;
}
export default function ConfirmBlock(props: Props) {
  const {
    blockOption,
    closeDialog
  } = props;
  const {
    logout
  } = useContext(AuthContext);
  function handleConfirm() {
    postSelfExclusionOption(blockOption).then(res => {
      toastSuccess(<Typography translateGroup="self-block-confirmation" translateKey="successfully-self-blocked" weight={600} />);
      logout();
      closeDialog();
    }).catch(err => {
      <Typography translateGroup="self-block-confirmation" translateKey="something-went-wrong" weight={600} />;
    });
  }
  return <Card style={{
    width: '468px',
    maxWidth: 'calc(100vw - 2rem)'
  }} data-sentry-element="Card" data-sentry-component="ConfirmBlock" data-sentry-source-file="confirmBlock.tsx">
            <Grid gap="1rem" data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                <Grid horizontalAlgin="center" padding={['pt-3']} data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                    <Typography translateGroup="self-block-confirmation" translateKey={`title-${blockOption}`} weight={600} size="lg" style={{
          width: '100%',
          textAlign: 'center'
        }} data-sentry-element="Typography" data-sentry-source-file="confirmBlock.tsx" />
                </Grid>
                <Grid horizontalAlgin="center" padding={['pt-3', 'pb-3']} data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                    <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                        <IoWarningOutline size={50} color="var(--danger)" data-sentry-element="IoWarningOutline" data-sentry-source-file="confirmBlock.tsx" />
                    </Grid>
                    <Typography translateGroup="self-exclusion" translateKey={blockOption} weight={600} size="lg" color="var(--primary)" style={{
          width: '100%',
          textAlign: 'center'
        }} data-sentry-element="Typography" data-sentry-source-file="confirmBlock.tsx" />
                </Grid>
                <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                    <Typography translateGroup="self-block-confirmation" translateKey="warn-text" style={{
          width: '100%',
          textAlign: 'center'
        }} data-sentry-element="Typography" data-sentry-source-file="confirmBlock.tsx" />
                </Grid>
                <Grid horizontalAlgin="space-between" padding={['pt-3', 'pe-3', 'ps-3', 'pb-2']} data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                    <Grid width={45} data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                        <Button id="cancel" type="button" block onClick={() => closeDialog()} color="outline-primary" data-sentry-element="Button" data-sentry-source-file="confirmBlock.tsx">
                            <Typography translateGroup="self-block-confirmation" translateKey="cancel" weight={600} data-sentry-element="Typography" data-sentry-source-file="confirmBlock.tsx" />
                        </Button>
                    </Grid>
                    <Grid width={45} data-sentry-element="Grid" data-sentry-source-file="confirmBlock.tsx">
                        <Button id="proceed" type="button" block onClick={() => handleConfirm()} color="primary" data-sentry-element="Button" data-sentry-source-file="confirmBlock.tsx">
                            <Typography translateGroup="self-block-confirmation" translateKey="proceed" weight={600} data-sentry-element="Typography" data-sentry-source-file="confirmBlock.tsx" />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Card>;
}