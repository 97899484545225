/* eslint-disable react/prop-types */
import React from 'react';
export default function LiveKasinoIcon({
  width = 25,
  height = 25,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="LiveKasinoIcon" data-sentry-source-file="liveKasino.tsx">
      <g clipPath="url(#clip0_10402_6864)" data-sentry-element="g" data-sentry-source-file="liveKasino.tsx">
        <path d="M0 10.4472H3.5272C3.75989 9.42519 4.16274 8.46881 4.70499 7.60777L2.21001 5.11279C1.09373 6.66472 0.320841 8.48009 0 10.4472Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M10.4477 3.5272V0C8.48058 0.320841 6.66521 1.09373 5.11328 2.21001L7.60826 4.70499C8.4693 4.16376 9.4267 3.75989 10.4477 3.5272Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M0 14.5527C0.320841 16.5198 1.09373 18.3352 2.21001 19.8871L4.70499 17.3921C4.16376 16.5311 3.75989 15.5737 3.5272 14.5527H0Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M14.5527 3.5272C15.5747 3.75989 16.5311 4.16274 17.3921 4.70499L19.8871 2.21001C18.3352 1.09373 16.5198 0.320841 14.5527 0V3.5272Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M24.9999 10.4472C24.6791 8.48009 23.9062 6.66472 22.7899 5.11279L20.2949 7.60777C20.8361 8.46881 21.24 9.42621 21.4727 10.4472H24.9999Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M20.2949 17.3931L22.7899 19.8881C23.9062 18.3362 24.6791 16.5208 24.9999 14.5537H21.4727C21.24 15.5757 20.8372 16.5321 20.2949 17.3931Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M5.11328 22.7899C6.66521 23.9062 8.48058 24.6791 10.4477 24.9999V21.4727C9.42568 21.24 8.4693 20.8372 7.60826 20.2949L5.11328 22.7899Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M14.5527 21.4727V24.9999C16.5198 24.6791 18.3352 23.9062 19.8871 22.7899L17.3921 20.2949C16.5311 20.8361 15.5737 21.24 14.5527 21.4727Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
        <path d="M19.1744 12.5003C19.1744 8.8142 16.1864 5.82617 12.5003 5.82617C8.8142 5.82617 5.82617 8.8142 5.82617 12.5003C5.82617 16.1864 8.8142 19.1744 12.5003 19.1744C16.1864 19.1744 19.1744 16.1864 19.1744 12.5003ZM12.5003 17.8839C9.52661 17.8839 7.11671 15.474 7.11671 12.5003C7.11671 9.52661 9.52661 7.11671 12.5003 7.11671C15.474 7.11671 17.8839 9.52661 17.8839 12.5003C17.8839 15.474 15.474 17.8839 12.5003 17.8839Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="liveKasino.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="liveKasino.tsx">
        <clipPath id="clip0_10402_6864" data-sentry-element="clipPath" data-sentry-source-file="liveKasino.tsx">
          <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="liveKasino.tsx" />
        </clipPath>
      </defs>
    </svg>;
}