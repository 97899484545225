import AuthContext from 'context/auth';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getXpBonusesProgress } from 'utils/services/api/requests/bonuses';
const defaultXpProgress = {
  name: '',
  description: '',
  graphUrl: '',
  maxTierLevel: 0,
  xp: 0,
  tierLevel: 0,
  currentTierXp: 0,
  tierChangedAt: '',
  leftToNextTier: 0,
  nextTierXp: 0,
  nextTiers: [{
    name: '',
    description: '',
    graphUrl: '',
    xp: 0
  }]
};
const useXpBonusProgress = () => {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const queryResult = useQuery(['xp-bonus-progress', token], getXpBonusesProgress, {
    enabled: !!isAuthenticated
  });
  const {
    data,
    isLoading,
    error
  } = queryResult;
  const {
    currentTierXp,
    nextTierXp,
    xp
  } = data || {};
  const preProgress = xp >= currentTierXp && xp <= nextTierXp ? (xp - currentTierXp) / (nextTierXp - currentTierXp) * 100 : 0;
  const progress = Math.min(100, Math.max(0, Math.round(preProgress * 100) / 100));
  return {
    data: isLoading ? defaultXpProgress : data,
    isLoading,
    error,
    progress
  };
};
export default useXpBonusProgress;