import { useState, useRef, useEffect, ChangeEvent, useContext } from 'react';
import { useRouter } from 'next/router';
import GlobalContext from 'context/global';
import AuthContext from 'context/auth';
import { MobileNavbarLoggedSections } from 'context/global/types';
import { tuohiLaunchLogin } from 'utils/services/api/requests/userEnv';
import { menuMobileAnimation } from '../menuAnimation';
import { searchAnimation } from '../animationFade';
import styles from './styles.module.scss';
const SECTIONS_THAT_NO_BACK = ['nav', 'menu', 'search'];
export function useLoggedOutMobileMenu() {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [menuHeight, setMenuHeight] = useState<number | null>(null);
  const [filter, setFilter] = useState<string>('');
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [url, setUrl] = useState('');
  const {
    section,
    setSection
  } = useContext(GlobalContext);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    asPath
  } = useRouter();
  const isSectionIncluded = SECTIONS_THAT_NO_BACK.includes(section);
  useEffect(() => {
    document.body.classList.toggle(styles.noScroll, section !== 'nav');
    return () => document.body.classList.remove(styles.noScroll);
  }, [section]);
  useEffect(() => {
    setSection('nav');
  }, [asPath]);
  useEffect(() => {
    const mobileMenu = document.getElementById('mobile-menu');
    const mobileInputFilter = document.getElementById('search-input-container');
    if (mobileMenu) {
      menuMobileAnimation({
        state: section === 'nav' ? 'collapse' : section === 'campaigns' ? 'full-expand' : 'expand',
        menu: mobileMenu
      });
    }
    if (mobileInputFilter) {
      searchAnimation({
        state: section === 'search' ? 'start' : 'finish',
        element: mobileInputFilter
      });
    }
  }, [section]);
  useEffect(() => {
    const mobileMenu = document.getElementById('mobile-menu');
    const handleScroll = () => setIsFixed(!!mobileMenu?.scrollTop);
    mobileMenu?.addEventListener('scroll', handleScroll);
    return () => mobileMenu?.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    sessionStorage.setItem('url-after-login', asPath || '/');
    tuohiLaunchLogin().then(res => {
      if (res.userFormUrl) setUrl(res.userFormUrl);
    }).catch(() => {});
  }, [asPath]);
  useEffect(() => {
    setMenuHeight(section === 'campaigns' ? window.innerHeight : menuRef.current?.offsetHeight ?? null);
  }, [menuRef, section]);
  const toggleMenuSection = () => {
    setSection((current: MobileNavbarLoggedSections) => current === 'nav' ? 'menu' : 'nav');
    setFilter('');
  };
  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  const openSearch = () => setSection('search');
  return {
    menuRef,
    menuHeight,
    filter,
    setFilter,
    isFixed,
    url,
    section,
    setSection,
    isAuthenticated,
    asPath,
    isSectionIncluded,
    toggleMenuSection,
    handleFilterChange,
    openSearch
  };
}