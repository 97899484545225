import { api } from '../..';
import urls from '../urls';
export interface BonusI {
  id: number;
  code: string;
  type: string;
  internalName: string;
  displayKeyword: string;
  validFrom: string;
  validUntil: string;
  enabled: boolean;
  validityDays: number;
  icon: string;
  isPublic: boolean;
  isPromoted: boolean;
  graph: string;
  graphBackground: string;
  createdDate: string;
  activations: number;
  wageringRequirement: number;
  minDeposit: number;
  valueInPercents: number;
  campaignUuid: null | string;
  campaignName: null | string;
  betValue: null | number;
  betCount: null | number;
  gameCode: null | string;
}
export async function getAllBonuses() {
  return api.get(urls.bonus.getAllBonuses).then(res => {
    return res.data.filter((item: any) => item.isPublic && item.type === 'deposit_bonus');
  });
}
export async function getAcceptedBonuses() {
  return api.get(urls.bonus.getAcceptedBonuses).then(res => {
    return res.data.filter((item: any) => item.type === 'deposit_bonus');
  });
}
interface AssociatedBonusToUserI {
  bonusId: string | number;
}
export async function associateBonusToUser({
  bonusId
}: AssociatedBonusToUserI) {
  return api.get(`${urls.bonus.associateUserToBonuses}/${bonusId}`);
}
export async function getBonusesHistory() {
  return api.get(urls.bonus.history).then(res => res.data);
}
export async function getUserBonus() {
  return api.get(urls.bonus.getActiveBonus).then(res => res.data);
}
export async function cancelUserBonus(bonusId: string) {
  return api.get(`${urls.bonus.cancelBonus}/${bonusId}`);
}
export async function activateUserBonus(bonusId: string | number) {
  return api.get(`${urls.bonus.activateUserBonus}/${bonusId}`);
}
export async function getXpBonusesProgress() {
  return api.get(`${urls.bonus.xpProgress}`).then(res => res.data);
}
export async function getTuohirullaList() {
  return api.get(urls.bonus.listTuohirulla).then(res => res.data);
}
export async function getTuohirullaDetails(code: string) {
  return api.get(urls.bonus.populateTuohirulla.replace('{{code}}', code)).then(res => res.data).catch(err => null);
}