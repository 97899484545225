import ExpandCollapseCard from 'components/cards/expandCollapseCard';
import { SectionProps } from 'components/navbar/mobile/loggedIn';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import BlocksTabContent from 'layouts/profile/sections/tabs/blocks/tabs/block';
export function BlockSelfSection({
  state,
  handleState
}: SectionProps) {
  return <ExpandCollapseCard color="white" value={state} header={<Grid horizontalAlgin="center" onClick={handleState}>
          <Typography translateGroup="blocks-section" translateKey="self-block" weight={600} style={{
      textTransform: 'uppercase'
    }} />
        </Grid>} data-sentry-element="ExpandCollapseCard" data-sentry-component="BlockSelfSection" data-sentry-source-file="index.tsx">
      <Grid padding={['pt-3']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <BlocksTabContent data-sentry-element="BlocksTabContent" data-sentry-source-file="index.tsx" />
      </Grid>
    </ExpandCollapseCard>;
}