import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import UserBalanceOnlyReal from 'components/userBalance/onlyReal';
export default function Balance() {
  return <Grid gap="1rem" padding={['pb-5']} data-sentry-element="Grid" data-sentry-component="Balance" data-sentry-source-file="balance.tsx">
      <Grid gap="0.5rem" width="calc(50% - 0.5rem)" data-sentry-element="Grid" data-sentry-source-file="balance.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="balance.tsx">
          <Typography translateGroup="global" translateKey="Nostettavissa" style={{
          width: '100%',
          textAlign: 'center',
          letterSpacing: '0.1rem',
          fontSize: '14px'
        }} color="#fff" data-sentry-element="Typography" data-sentry-source-file="balance.tsx" />
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="balance.tsx">
          <Typography weight={800} style={{
          width: '100%',
          textAlign: 'center',
          fontSize: '20px'
        }} color="#fff" data-sentry-element="Typography" data-sentry-source-file="balance.tsx">
            <UserBalanceOnlyReal refetchInterval={false} data-sentry-element="UserBalanceOnlyReal" data-sentry-source-file="balance.tsx" />
          </Typography>
        </Grid>
      </Grid>
    </Grid>;
}