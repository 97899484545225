import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
interface NotificationProps {
  value: number;
}
export function Notification({
  value
}: NotificationProps) {
  if (value < 1) {
    return <></>;
  }
  return <Grid verticalAlgin="center" horizontalAlgin="center" style={{
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    background: 'var(--currency)'
  }} data-sentry-element="Grid" data-sentry-component="Notification" data-sentry-source-file="notification.tsx">
      <Typography translateGroup="notification" translateKey={`${value}`} color="var(--primary)" size="md" data-sentry-element="Typography" data-sentry-source-file="notification.tsx" />
    </Grid>;
}