import Typography from 'components/uiKit/typography';
import AuthContext from 'context/auth';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toastError, toastSuccess } from 'utils/functions/notifications';
import { userProfileInterface } from 'utils/globalTypes/users';
import CountriesApi from 'utils/services/api/requests/countries';
import { getProfile, isProfileVerified, updateProfile } from 'utils/services/api/requests/userEnv';
import validateForm from './formValidation';
import { parseForm, parseProfileState } from './functions';
function updateCount(object: any) {
  const count = Object.keys(object).reduce((acc, key) => {
    if (object[key] !== '' && key !== 'count') {
      return acc + 1;
    }
    return acc;
  }, 0);
  object.count = count;
  return object;
}
export function useProfileHook() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<userProfileInterface>();
  const [errors, setErrors] = useState<any>({
    count: 0
  });
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    isLoading,
    error,
    refetch: refreshProfile
  } = useQuery(['profile', token], getProfile, {
    enabled: !!isAuthenticated,
    onSuccess: response => {
      if (!formData) {
        setFormData(parseProfileState(response));
      }
    }
  });
  const {
    data: profileValidationInfo,
    isLoading: isLoadingProfileInfo
  } = useQuery(['profile-validation-info', token], isProfileVerified, {
    enabled: !!isAuthenticated,
    staleTime: 12000
  });
  const {
    data: countries,
    isLoading: isLoadingCountries
  } = useQuery('countries', CountriesApi.getItems, {});
  useEffect(() => {
    refreshProfile();
  }, []);
  function updateField(fieldName: string, value: any) {
    setErrors((d: any) => {
      const e = {
        ...d,
        [fieldName]: ''
      };
      return updateCount(e);
    });
    setFormData((d: any) => {
      return {
        ...d,
        [fieldName]: value
      };
    });
  }
  function updateFieldToogle(fieldName: string, value: any) {
    setErrors((d: any) => {
      const e = {
        ...d,
        [fieldName]: ''
      };
      return updateCount(e);
    });
    setFormData((d: any) => {
      return {
        ...d,
        [fieldName]: value
      };
    });
  }
  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const newForm = {
      ...formData
    };
    const form = parseForm(newForm);
    const validation = validateForm(form as any);
    if (validation.count) {
      setErrors((d: any) => {
        return {
          ...d,
          ...validation
        };
      });
      setLoading(false);
    } else {
      updateProfile(form).then(response => {
        toastSuccess(<Typography translateGroup="profile-form" translateKey="update-success" weight={600} />);
        setLoading(false);
        refreshProfile();
      }).catch(err => {
        toastError(<Typography translateGroup="profile-form" translateKey="update-error" weight={600} />);
        setLoading(false);
      });
    }
  }
  const countryInfo = countries?.find((country: any) => country?.iso2Code === formData?.countryCode);
  function handlerChangePhone({
    phoneNumber,
    phonePrefix,
    country,
    verified
  }: any) {
    updateField('mobileNumber', phoneNumber);
    updateField('mobilePrefix', phonePrefix);
    updateField('countryInfo', country);
    if (!formData?.countryCode) {
      updateField('countryCode', country?.iso2);
    }
    updateField('mobileNumberChecked', verified);
    setErrors((d: any) => {
      return {
        ...d,
        phoneNumber: ''
      };
    });
  }
  return {
    formData,
    countryInfo,
    handleSubmit,
    errors,
    isLoading: isLoading || isLoadingCountries,
    loading,
    updateField,
    handlerChangePhone,
    updateFieldToogle,
    profileValidationInfo
  };
}