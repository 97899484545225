import React from 'react';
import PartyPopperIcon from 'assets/icons/partyPopperIcon';
import { textTranslated } from 'components/textTranslated';
import Button from 'components/uiKit/buttons';
interface LevelUpProps {
  onClick?: () => void;
  closeToast?: () => void;
}
export const LevelUp: React.FC<LevelUpProps> = ({
  onClick,
  closeToast
}) => <div className="flex flex-row items-center justify-between" data-sentry-component="LevelUp" data-sentry-source-file="LevelUp.tsx">
    <div className="xs:mr-2 sm:mr-4">
      <PartyPopperIcon className="xs:w-9 xs:h-9 sm:w-11 sm:h-11" data-sentry-element="PartyPopperIcon" data-sentry-source-file="LevelUp.tsx" />
    </div>
    <div className="text-white [&_h3]:font-bold [&_h3]:text-base [&_h3]:leading-5 [&_p]:text-sm [&_p]:leading-4">
      {textTranslated({
      group: 'notification',
      key: 'levelUp'
    })}
    </div>
    <Button color="yellow" size="sm" id="rollNow" className="w-[110px] whitespace-nowrap font-bold text-sm tracking-tighter" onClick={() => {
    onClick?.();
    closeToast?.();
  }} data-sentry-element="Button" data-sentry-source-file="LevelUp.tsx">
      {textTranslated({
      group: 'notification',
      key: 'rollNow'
    })}
    </Button>
  </div>;