import AuthContext from 'context/auth';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getSelfExclusionOptions } from 'utils/services/api/requests/userEnv';
export function useBlockHook() {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const {
    data,
    isLoading
  } = useQuery(['self-exclusion-options', token], getSelfExclusionOptions, {
    enabled: !!isAuthenticated
  });
  const {
    options
  } = data || {
    options: []
  };
  return {
    options,
    isLoading
  };
}