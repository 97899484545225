export function parseProfileState(accountInfo: any) {
    const profile = { ...accountInfo, ...accountInfo.userProfileDTO, ...accountInfo.userProfile }
    delete profile.userProfileDTO
    delete profile.userProfile
    const keys = Object.keys(profile)
    keys.forEach((key) => {
        if (!profile[key]) {
            profile[key] = ''
        }
    })
    return profile
}

export function parseForm(form: any) {
    const formParsed = {
        activated: form.activated,
        alias: form.alias,
        authorities: form.authorities,
        createdBy: form.createdBy,
        createdDate: form.createdDate,
        email: form.email,
        firstName: form.firstName,
        id: form.id,
        imageUrl: form.imageUrl,
        langKey: form.langKey,
        lastModifiedBy: form.alias,
        lastModifiedDate: form.lastModifiedDate,
        lastName: form.lastName,
        login: form.login,
        userProfileDTO: {
            address1: form.address1,
            address2: form.address2,
            city: form.city,
            countryCode: form.countryCode,
            dateOfBirth: form.dateOfBirth,
            enabled: true,
            gender: form.gender,
            jurisdiction: form.jurisdiction,
            language: form.language,
            marketing: form.marketing,
            mobileNumber: form.mobileNumber,
            mobileNumberChecked: form.mobileNumberChecked,
            mobilePrefix: form.mobilePrefix,
            postCode: form.postCode,
            splitTestId: form.splitTestId,
            state: form.state,
            version: form.version,
            emailMarketing: form.emailMarketing,
            smsMarketing: form.smsMarketing,
        },
    }
    return formParsed
}
