import Loading from 'assets/loading';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import { useEffect, useRef, useState } from 'react';
import { TbFaceIdError } from 'react-icons/tb';
import { tuohiLaunchLogin } from 'utils/services/api/requests/userEnv';
interface Props {
  originUrl: string;
}
export default function CustomLoginForm({
  originUrl
}: Props) {
  const [status, setStatus] = useState<'loading' | 'form' | 'error'>('loading');
  const [url, setUrl] = useState('');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeContent, setIframeContent] = useState<string>('');
  useEffect(() => {
    sessionStorage.setItem('url-after-login', originUrl || '/');
    tuohiLaunchLogin().then(res => {
      if (res.userFormUrl) {
        setStatus('form');
        setUrl(res.userFormUrl);
      } else {
        setStatus('error');
      }
    }).catch(err => {
      setStatus('error');
    });
  }, []);
  // useEffect(() => {
  //     if (iframeRef.current) {
  //         const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document
  //         if (iframeDocument) {
  //           const iframeBody = iframeDocument.body
  //           if (iframeBody) {
  //             setIframeContent(iframeBody.innerHTML)
  //           }
  //         }
  //       }
  //     //   console.log({ iframeRef2: iframeRef?.current?.offsetParent, iframeRef })
  //   }, [iframeRef])
  //   console.log({ iframeContent, iframeRef2: iframeRef?.current })
  // useEffect(() => {
  //     const root = document.getElementById('root')
  //     console.log({ root })
  //     if (root) {
  //         root.style.contain = 'inline-size'
  //     }
  // }, [])
  // useEffect(() => {
  //     if (iframeRef.current) {
  //       const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
  //       if (iframeDocument) {
  //         const iframeBody = iframeDocument.body;
  //         if (iframeBody) {
  //           console.log('Conteúdo do iframe:', iframeBody.innerHTML);
  //           iframeBody.style.contain = 'inline-size';
  //         }
  //       }
  //     }
  //   }, []);
  useEffect(() => {
    const checkIframeContent = () => {
      if (iframeRef.current) {
        const iframe = iframeRef.current;
        const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDocument) {
          const root = iframeDocument.getElementById('root');
          if (root) {
            root.style.contain = 'inline-size';
          } else {
            setTimeout(checkIframeContent, 100);
          }
        }
      }
    };
    if (iframeRef.current) {
      iframeRef.current.onload = checkIframeContent;
    }
  }, []);
  if (status === 'loading') {
    return <Grid verticalAlgin="center" horizontalAlgin="center">
        <Loading />
      </Grid>;
  }
  if (status === 'error') {
    return <Grid padding={['p-3']} gap="1rem" horizontalAlgin="center">
        <Grid style={{
        color: 'var(--danger)'
      }} horizontalAlgin="center">
          <TbFaceIdError size={50} />
        </Grid>
        <Typography translateGroup="login" translateKey="login-load-error" />
      </Grid>;
  }
  return <Grid horizontalAlgin="center" data-sentry-element="Grid" data-sentry-component="CustomLoginForm" data-sentry-source-file="index.tsx">
      <iframe title="login" src={url} ref={iframeRef} style={{
      borderRadius: '1rem',
      border: 'none',
      width: '42.2rem',
      height: '48.5rem',
      maxHeight: 'calc(100dvh - 90px)',
      maxWidth: 'calc(100% - 2rem)'
    }} />
    </Grid>;
}