import GlobalContext from 'context/global';
import React, { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NavbarMobile from './mobile';
import NavbarDesktop from './desktop';
import { TuohirullaNotification } from './TuohirullaNotification';
interface NavbarContextInterface {
  filter: string;
  setFilter: Function;
}
export const NavbarContext = createContext<NavbarContextInterface>({
  filter: '',
  setFilter: () => {}
});
export default function Navbar() {
  const {
    appType
  } = React.useContext(GlobalContext);
  const {
    asPath
  } = useRouter();
  const [filter, setFilter] = useState<string>('');
  useEffect(() => {
    const url = new URL(window.location.toString());
    if (url.searchParams.has('search')) {
      const searchParam = url.searchParams.get('search') ?? '';
      setFilter(searchParam);
    } else {
      setFilter('');
    }
  }, [asPath]);
  function setCurrentSearch(search: string) {
    const url = new URL(window.location.toString());
    if (!search) {
      url.searchParams.delete('search');
    } else {
      url.searchParams.set('search', String(search));
    }
    window.history.pushState({}, '', url);
    setFilter(search);
  }
  return <NavbarContext.Provider value={{
    filter,
    setFilter: setCurrentSearch
  }} data-sentry-element="unknown" data-sentry-component="Navbar" data-sentry-source-file="index.tsx">
      {appType === 'mobile' ? <NavbarMobile /> : <NavbarDesktop />}

      <TuohirullaNotification data-sentry-element="TuohirullaNotification" data-sentry-source-file="index.tsx" />
    </NavbarContext.Provider>;
}