import React, { useState } from 'react';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import Button from 'components/uiKit/buttons';
import { activateUserBonus, associateBonusToUser } from 'utils/services/api/requests/bonuses';
import { toastError, toastSuccess } from 'utils/functions/notifications';
import { queryClient } from 'pages/_app';
import { useRouter } from 'next/router';
import { gaAnalytics } from 'utils/functions/track/gaAnalytics';
import { replaceDashesToHyphens } from 'utils/functions/strings';
import styles from './styles.module.scss';
interface Props {
  count: number;
  gameName: string;
  background: string;
  gameCode: string;
  freeSpinId: any;
}
export default function AvailableFreeSpinsCard(props: Props) {
  const {
    count,
    gameName,
    background,
    gameCode,
    freeSpinId
  } = props;
  const [loading, setLoading] = useState(false);
  const {
    push
  } = useRouter();
  function handleAssociateBonus() {
    setLoading(true);
    gaAnalytics.trackButtonClick({
      category: 'Available Free spin',
      buttonId: 'free-spin-cta',
      value: freeSpinId
    });
    associateBonusToUser({
      bonusId: freeSpinId
    }).then(res => {
      activateUserBonus(res?.data?.id).then(res1 => {
        setLoading(false);
        toastSuccess(<Typography translateGroup="bonuses" translateKey="free-spin-successfully-active" />);
        queryClient.refetchQueries();
        push(`/games/launch/${replaceDashesToHyphens(gameCode)}`);
      }).catch(err => {
        setLoading(false);
        toastError(<Typography translateGroup="global" translateKey="something-went-wrong" />, {
          autoClose: false
        });
      });
    }).catch(err => {
      setLoading(false);
      toastError(<Typography translateGroup="global" translateKey="something-went-wrong" />);
    });
  }
  return <Grid hidden={!count} className={styles.wrapper} verticalAlgin="space-between" padding={['p-3']} gap="0.5rem" style={{
    backgroundImage: background ? `url(${background})` : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: '100%',
    aspectRatio: '312 / 178'
  }} data-sentry-element="Grid" data-sentry-component="AvailableFreeSpinsCard" data-sentry-source-file="index.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Typography style={{
          fontSize: '2rem'
        }} weight={800} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {`${count || 0} X`}
          </Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Typography weight={800} style={{
          textTransform: 'uppercase',
          fontSize: '1rem'
        }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {gameName}
          </Typography>
        </Grid>
      </Grid>
      <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Button disabled={loading} id="free-spin-cta" color="yellow" onClick={() => handleAssociateBonus()} style={{
        maxWidth: '172px',
        width: '100%'
      }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <Typography translateGroup="free-spin-active-card" translateKey="active-free-spin" size="md" style={{
          padding: '0 2rem'
        }} weight={700} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
        </Button>
      </Grid>
    </Grid>;
}