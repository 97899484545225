import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import { uiKitInputProps } from 'components/uiKit/inputs/inputGroup';
import Typography from 'components/uiKit/typography';
import { createContext } from 'react';
import InputGroupNew from 'components/uiKit/inputs/inputNew';
import styles from './styles.module.scss';
export const PhoneNumberContext = createContext<any>({});
export default function EmailInput(props: any) {
  const {
    inputProps
  } = props;
  return <Grid style={{
    position: 'relative'
  }} data-sentry-element="Grid" data-sentry-component="EmailInput" data-sentry-source-file="index.tsx">
      <InputGroupNew {...inputProps as uiKitInputProps} {...props} inputProps={{
      style: {
        paddingRight: '33%'
      }
    }} data-sentry-element="InputGroupNew" data-sentry-source-file="index.tsx" />
      <div className={styles['value-wrapper']}>
        <Button id="profile-update-submit" type="submit" color="yellow" disabled={Boolean(inputProps?.readOnly)} style={{
        width: '5rem',
        maxWidth: '80px',
        height: '2.25rem',
        maxHeight: '36px'
      }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <Typography translateGroup="global" translateKey="LÄHETÄ" size="sm" weight={700} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
        </Button>
      </div>
    </Grid>;
}