// MobileNavbarLoggedIn.jsx
import BackArrow from 'assets/icons/backArrow';
import HamburgerMenuIcon from 'assets/icons/mobileHamburguerIcon';
import SearchIcon from 'assets/icons/searchIcon';
import AppLogo from 'assets/logo/appLogo';
import Link from 'components/customLink';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import BonusesSection from 'sections/bonuses';
import CampaignSection from 'sections/campaigns';
import HamburgerMenu from 'sections/Menu';
import SearchGames from 'sections/searchGames';
import { textTranslated } from 'components/textTranslated';
import WrapperEditTranslation from 'components/textTranslated/wrapperEditTranslation';
import { MouseEventHandler } from 'react';
import { useBonuses } from 'utils/customHooks/useBonuses';
import { useLoggedMobileMenu } from './hook';
import styles from './styles.module.scss';
export interface SectionProps {
  state: 'expand' | 'collapse';
  handleState: MouseEventHandler<HTMLDivElement>;
}
export default function MobileNavbarLoggedIn() {
  const {
    filter,
    isFixed,
    isSectionIncludeInList,
    handlerHamburger,
    handleFilterChange,
    MENUPROFILE,
    stateProfile,
    setStateProfile,
    translateTitles,
    section,
    setSection
  } = useLoggedMobileMenu();
  const {
    count: bonusCount
  } = useBonuses();
  return <Grid id="mobile-menu" data-section={section} className={styles.wrapper} gap="1rem" verticalAlgin="flex-start" padding={['ps-3', 'pe-3']} data-sentry-element="Grid" data-sentry-component="MobileNavbarLoggedIn" data-sentry-source-file="index.tsx">
      <Grid data-is-fixed={isFixed || section !== 'nav'} className={styles.nav} horizontalAlgin="space-between" verticalAlgin="center" height="66px" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid horizontalAlgin="flex-start" style={{
        color: '#fff'
      }} verticalAlgin="center" width={33} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {isSectionIncludeInList ? <Link href="/">
              <AppLogo color="white" height="30px" width="100px" />
            </Link> : <BackArrow onClick={() => setSection('menu')} />}
        </Grid>
        <Grid verticalAlgin="center" horizontalAlgin="center" responsiveWidth={{
        sm: 25,
        md: 33
      }} style={{
        color: '#fff'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid hidden={isSectionIncludeInList} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Typography translateGroup="nav-bar-menu-title" translateKey={translateTitles[section] || section} weight={700} color="#fff" style={{
            textTransform: 'uppercase'
          }} algin="center" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
          </Grid>
        </Grid>
        <Grid horizontalAlgin="flex-end" verticalAlgin="center" gap="1rem" responsiveWidth={{
        sm: 40,
        md: 33
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid onClick={() => setSection('search')} width="fit-content" gap="0.5rem" verticalAlgin="center" style={{
          color: '#fff'
        }} hidden={section === 'search' || section !== 'menu' && section !== 'nav'} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="index.tsx" />
            <Typography translateGroup="mobile-nav" translateKey="Hae" color="#fff" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
          </Grid>
          <Grid width="3rem" height="100%" verticalAlgin="center" horizontalAlgin={section === 'nav' ? 'center' : 'flex-end'} style={{
          position: 'relative'
        }} onClick={handlerHamburger} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            {bonusCount > 0 && section === 'nav' && <span style={{
            position: 'absolute',
            top: '-15px',
            right: '0px'
          }}>
                <Grid verticalAlgin="center" horizontalAlgin="center" style={{
              borderRadius: '50%',
              width: '1.2rem',
              height: '1.2rem',
              background: 'var(--currency)'
            }}>
                  <Typography translateGroup="notification" translateKey={`${bonusCount}`} color="var(--primary)" size="sm" />
                </Grid>
              </span>}
            <HamburgerMenuIcon isOpen={section !== 'nav'} data-sentry-element="HamburgerMenuIcon" data-sentry-source-file="index.tsx" />
          </Grid>
        </Grid>

      </Grid>

      {section !== 'nav' && <Grid className={styles.content}>
            <Grid data-section={section} verticalAlgin={section === 'search' ? 'flex-start' : 'center'} style={{
        placeContent: section === 'menu' ? 'end' : 'baseline'
      }}>
              {section === 'menu' && <HamburgerMenu section={section} setSection={setSection} />}
              {section === 'menu-profile' && <Grid gap="0.5rem">
                  {MENUPROFILE.map(({
            key,
            Component
          }) => <Component key={key} state={stateProfile === key ? 'expand' : 'collapse'} handleState={() => setStateProfile(stateProfile === key ? '' : key)} />)}
                </Grid>}
              {section === 'search' && <Grid>
                    <Grid hidden={section !== 'search'} id="search-input-container" className={styles['filter-input']} padding={['p-3']} wrap="nowrap">
                      <WrapperEditTranslation group="search" translateKey="search-key">
                        <input id="filter" name="filter" style={{
                padding: 0
              }} placeholder={textTranslated({
                group: 'search',
                key: 'search-key'
              })} onChange={handleFilterChange} />
                      </WrapperEditTranslation>
                      <SearchIcon width={16} height={16} />
                    </Grid>
                    <SearchGames filter={filter} />
                  </Grid>}
              {section === 'rewards' && <Grid>
                  <BonusesSection />
                </Grid>}
              {section === 'campaigns' && <CampaignSection />}
            </Grid>
          </Grid>}
    </Grid>;
}