import { useContext, useEffect, useRef, useState } from 'react';
import DialogContext from 'context/dialog';
import GlobalContext from 'context/global';
import { MobileNavbarLoggedSections } from 'context/global/types';
import FloatMenu from 'components/navbar/desktop/components/floatMenu';
import { useProfile } from 'utils/customHooks/useProfile';
import styles from './styles.module.scss';
const PREV_PROGRESS = 'PREV_PROGRESS_';
const getPercent = (storageKey: string) => {
  if (typeof window !== 'undefined') {
    const storedProgress = localStorage.getItem(storageKey);
    if (storedProgress !== null) {
      return parseFloat(storedProgress);
    }
  }
  return 0;
};
interface BarProgressProps {
  progress: number;
  width?: string;
  height?: number;
  border?: 'primary' | 'secondary';
}
export function ProgressBar({
  progress,
  width,
  height,
  border
}: BarProgressProps) {
  const {
    appType
  } = useContext(GlobalContext);
  const {
    displayDialog,
    removeDialog
  } = useContext(DialogContext);
  const {
    setSection
  } = useContext(GlobalContext);
  const {
    profile
  } = useProfile();
  const storageKey = [PREV_PROGRESS, profile?.id].join('_');
  const [percent, setPercent] = useState(getPercent(storageKey));
  const [playAnimation, setPlayAnimation] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (percent > 0 && percent < progress) {
      setPercent(progress);
      localStorage.setItem(storageKey, `${progress}`);
      setPlayAnimation(true);
      const timeOut = setTimeout(() => {
        setPlayAnimation(false);
      }, 1000);
      return () => clearTimeout(timeOut);
    }
    if (percent > 0 && percent > progress) {
      setPercent(100);
      setPlayAnimation(true);
      const timeOut = setTimeout(() => {
        setPercent(progress);
        localStorage.setItem(storageKey, `${progress}`);
        setPlayAnimation(false);
      }, 1000);
      return () => clearTimeout(timeOut);
    }
    if (percent === 0) {
      setPercent(progress);
      localStorage.setItem(storageKey, `${progress}`);
    }
    const animationTimeOut = setTimeout(() => {
      setPlayAnimation(false);
    }, 1000);
    return () => clearTimeout(animationTimeOut);
  }, [progress]);
  function handleDisplayDialog() {
    if (appType !== 'mobile') {
      const cta = document.getElementById('navbar-float-menu-cta');
      if (cta) {
        const ctaBounds = cta.getBoundingClientRect();
        displayDialog({
          dialogId: 'FLOAT-MENU',
          content: <FloatMenu closeDialog={() => removeDialog('FLOAT-MENU')} menu="rewards" />,
          dialogProps: {
            anchor: 'position',
            anchorPosition: {
              x: ctaBounds.width + 5,
              y: -22,
              element: cta
            },
            displayClose: false
          }
        });
      }
    } else {
      setSection((current: MobileNavbarLoggedSections) => current === 'nav' ? 'rewards' : 'nav');
    }
  }
  return <div className={`relative cursor-pointer z-0 w-full h-[0.625rem] ${playAnimation ? styles.playAnimation : ''}`} data-sentry-component="ProgressBar" data-sentry-source-file="index.tsx">
      <div className={`${styles['progress-bar-container']} ${playAnimation ? styles.growing : ''}`} data-border={border || 'primary'} onClick={handleDisplayDialog}>
        <div className={styles.progress} style={{
        width: `${percent}%`
      }} />
      </div>
    </div>;
}