import Loading from 'assets/loading';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import DialogContext from 'context/dialog';
import { useContext } from 'react';
import ConfirmBlock from './confirmBlock';
import { useBlockHook } from './useBlockHook';
export default function BlocksTabContent() {
  const {
    options,
    isLoading
  } = useBlockHook();
  const {
    displayDialog,
    removeDialog
  } = useContext(DialogContext);
  function handleDisplayDialog(option: string) {
    displayDialog({
      dialogId: 'SELF-EXCLUSION-DIALOG',
      content: <ConfirmBlock blockOption={option} closeDialog={() => removeDialog('SELF-EXCLUSION-DIALOG')} />
    });
  }
  const keysTranslate: any = {
    DAYS_1: '24 tuntia',
    DAYS_7: 'Yksi viikko',
    MONTHS_1: '30 päivää',
    PERMANENT: 'Pysyvä'
  };
  if (options[0] === 'PERMANENT') {
    options.push(options.shift());
  }
  return <>
      <Grid horizontalAlgin="center" gap="0.5rem" padding={['pt-5']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {isLoading ? <Loading /> : options?.filter((op: any) => Object.keys(keysTranslate).includes(op))?.map((option: string) => <Button key={option} id={option} onClick={() => handleDisplayDialog(option)}>
              <Typography translateGroup="self-exclusion" translateKey={keysTranslate[option as any] as string || option} weight={600} />
            </Button>)}
      </Grid>
    </>;
}