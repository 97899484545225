import { useContext, useEffect, useState } from 'react';
import { useQueries, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import AuthContext from 'context/auth';
import { getTuohirullaList } from 'utils/services/api/requests/bonuses';
import FreeSpinsApi from 'utils/services/api/requests/freeSpins';
const QUERY_KEYS = ['all-wheels', 'accepted-freespins'];
export const useBonuses = () => {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const router = useRouter();
  const [staleTime, setStaleTime] = useState(60000);
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url === '/') {
        QUERY_KEYS.map(key => queryClient.invalidateQueries([key, token]));
        setStaleTime(0);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [queryClient, router.events]);
  const queries = [{
    queryKey: [QUERY_KEYS[0], token],
    queryFn: getTuohirullaList,
    staleTime,
    enabled: !!isAuthenticated
  }, {
    queryKey: [QUERY_KEYS[1], token],
    queryFn: FreeSpinsApi.getAcceptedItems,
    staleTime,
    enabled: !!isAuthenticated
  }];
  const results = useQueries(queries);
  const bonuses = results.map(result => result?.data || []).flat();
  return {
    isLoading: results?.[0].isLoading || results?.[1].isLoading,
    bonuses,
    count: bonuses.length
  };
};