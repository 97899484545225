import React, { useContext } from 'react';
import Typography from 'components/uiKit/typography';
import Grid from 'components/uiKit/grid';
import { BsSortDownAlt, BsSortUp } from 'react-icons/bs';
import { FaSort } from 'react-icons/fa';
import { DataTableContext } from '..';
export default function Header() {
  const {
    columns,
    sortState,
    toggleSort
  } = useContext(DataTableContext);
  return <Grid style={{
    position: 'relative',
    zIndex: '1'
  }} data-sentry-element="Grid" data-sentry-component="Header" data-sentry-source-file="index.tsx">
      <Grid wrap="nowrap" verticalAlgin="stretch" padding={['p-3']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {columns?.map(column => {
        const filterKey = column.key;
        return <Grid key={`header-${column.uniqueId}`} style={column.style} verticalAlgin="stretch">
              <Grid wrap="nowrap">
                {typeof column.label === 'string' ? <Typography translateGroup="input-group-label" translateKey={column.label} color={'#000' as any} /> : column.label || ''}
                {!!column.filter && <Grid width="20px">
                    {sortState?.column === filterKey ? <>
                        {sortState?.sort === 'asc' ? <BsSortDownAlt color="var(--text-color)" onClick={() => toggleSort(filterKey)} cursor="pointer" /> : <BsSortUp color="var(--text-color)" onClick={() => toggleSort(filterKey)} cursor="pointer" />}
                      </> : <>
                        <FaSort color="var(--text-color)" onClick={() => toggleSort(filterKey)} cursor="pointer" />
                      </>}
                  </Grid>}
              </Grid>
            </Grid>;
      })}
      </Grid>
    </Grid>;
}