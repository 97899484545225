import { api } from '../..'
import urls from '../urls'

async function getAvailableItems() {
    const res = await api.get(urls.freeSpins.getAvailableFreeSpins)
    return res.data
}
async function getAcceptedItems() {
    const res = await api.get(urls.freeSpins.getAcceptedFreeSpins)
    return res.data
}
async function getActiveItems() {
    const res = await api.get(urls.freeSpins.getActiveFreeSpins)
    return res.data
}

async function getHistory() {
    const res = await api.get(urls.freeSpins.getHistory)
    return res.data
}
async function getHistoryExchanges() {
    const res = await api.get(urls.freeSpins.exchange)
    return res.data
}

export interface AvailableFreeSpin {
    id: number;
    availableFreeSpinId: number;
    gameCode: string;
    currencyCode: string | null;
    betValue: number;
}

export interface ExchangeItemInterface {
    id: number;
    order: number;
    maxSpins: number;
    currencyCode: string;
    tokenCurrencyCode: string;
    exchangeRate: number;
    gameId: string;
    enabled: boolean;
    availableFreeSpins: AvailableFreeSpin[];
    game: any
}
async function getExchanges(currencyToken: string) {
    const res = await api.get(urls.freeSpins.getExchangeFreeSpins.replace('{{currencyToken}}', currencyToken))
    return res.data
}

interface exchangePayload {
    spinsQuantity: number;
    exchangeId: number;
    availableFreeSpinId: number;
}
async function exchangeFreeSpins(payload: exchangePayload) {
    const res = await api.post(urls.freeSpins.exchangeFreeSpins, payload)
    return res.data
}

const FreeSpinsApi = {
    getAvailableItems,
    getAcceptedItems,
    getActiveItems,
    getHistory,
    getExchanges,
    exchangeFreeSpins,
    getHistoryExchanges,
}

export default FreeSpinsApi
