import React from 'react';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import { gridProps } from 'components/uiKit/grid/types';
import { buttonProps } from 'components/uiKit/buttons/types';
import { typographySize } from 'components/uiKit/typography/types';
import InfoHover from '../../InfoHover';
import styles from './input.group.module.scss';
type btn = {
  value: any;
  label: any;
  info?: any;
};
interface onChangeI {
  target: {
    value: any;
    id: string;
    name: string;
  };
}
interface gridPropsI extends Omit<gridProps, 'children'> {}
interface btnPropsI extends Omit<buttonProps, 'id'> {}
interface props {
  value: any;
  onChange: (newValue: onChangeI) => void;
  name: string | any;
  options: Array<btn>;
  className?: any;
  readOnly?: any;
  gridProps?: gridPropsI;
  btnProps?: btnPropsI;
  status?: any;
  feedback?: React.ReactNode;
  fontSize?: typographySize | Array<typographySize>;
  styles?: React.CSSProperties;
}
function TypeButton({
  value,
  onChange,
  name,
  options,
  className,
  readOnly,
  gridProps: gProps,
  btnProps,
  status,
  feedback,
  fontSize,
  styles: stylesProps
}: props) {
  return <Grid {...gProps} data-sentry-element="Grid" data-sentry-component="TypeButton" data-sentry-source-file="index.tsx">
            <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
      ...stylesProps
    }}>
        {feedback && <div className={styles.feedback}>
            <Typography size="xsm">{feedback}</Typography>
          </div>}
            </div>
           <Grid gap="1rem" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                {options.map((btn: any) => {
        const active = value?.toString() === btn?.value?.toString();
        return <Button id={`type-button-${btn.value}`} disabled={readOnly} onClick={() => {
          onChange({
            target: {
              name,
              id: name,
              value: btn.value
            }
          });
        }} type="button" {...btnProps} key={btn.value} color={active ? 'yellow' : 'primary'}>

                                <Grid horizontalAlgin="center" verticalAlgin="center">
                                    {typeof btn.label === 'string' ? <Typography translateGroup="input-group-label" translateKey={btn.label} weight={600} /> : btn.label}
                                    {btn?.info ? <InfoHover content={btn?.info} /> : <></>}
                                </Grid>
                            </Button>;
      })}
           </Grid>
        </Grid>;
}
export default TypeButton;