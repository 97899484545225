import moment from 'moment';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { getBetTransactions, getWalletTransactions } from 'utils/services/api/requests/wallet';
import GlobalContext from 'context/global';
import AuthContext from 'context/auth';
import { useQuery } from 'react-query';
import { BetHistory, TransactionHistory } from './types';
const currency = 'EUR';
export function useHistoryHook() {
  const {
    isAuthenticated,
    token
  } = useContext(AuthContext);
  const [sanitizedFilter, setSanitizedFilter] = useState<any>([]);
  const {
    data: betTransactions,
    isLoading: isLoadingBet
  } = useQuery(['betTransactions', token], () => getBetTransactions(currency), {
    enabled: !!isAuthenticated,
    staleTime: 12000
  });
  const {
    data: cashierTransactions,
    isLoading: isLoadingCashier
  } = useQuery(['cashierTransactions', token], () => getWalletTransactions(currency), {
    enabled: !!isAuthenticated,
    staleTime: 12000
  });
  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
    mainTypeFilter: 'all'
  });
  const sanitizeFilterResult = (items: (TransactionHistory | BetHistory)[]) => {
    return items?.map(item => {
      return {
        date: item.createdDate,
        type: item.type,
        gameName: 'displayName' in item ? item.displayName : 'Zimpler',
        status: 'status' in item ? item.status : null,
        amount: Number(item.amount)
      };
    }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  };
  const sanitizedResults = sanitizeFilterResult([...(betTransactions || []), ...(cashierTransactions || [])]);
  const uniqueTypesSet = new Set(sanitizedResults.map(item => item.type));
  useEffect(() => {
    let newDate = sanitizedResults;
    if (dateFilter?.startDate || dateFilter.endDate) {
      newDate = newDate.filter(item => {
        const startDate = dateFilter.startDate ? dateFilter.startDate : '1970-01-01';
        const endDate = dateFilter.endDate ? dateFilter.endDate : moment(moment.utc().endOf('day')).format('YYYY-MM-DD');
        const itemDate = item.date.split('T')[0];
        return itemDate >= moment(startDate).format('YYYY-MM-DD') && itemDate <= moment(endDate).format('YYYY-MM-DD');
      });
    }
    if (dateFilter?.mainTypeFilter && dateFilter?.mainTypeFilter !== 'all') {
      newDate = newDate.filter(item => {
        return item.type === dateFilter?.mainTypeFilter;
      });
    }
    setSanitizedFilter(newDate);
  }, [betTransactions, cashierTransactions, dateFilter]);
  function handlerChange(event: ChangeEvent<HTMLInputElement>) {
    setDateFilter(current => ({
      ...current,
      [event.target.name]: event.target.value
    }));
  }
  return {
    dateFilter,
    sanitizedFilter,
    uniqueTypesSet,
    isLoading: isLoadingBet || isLoadingCashier,
    handlerChange
  };
}