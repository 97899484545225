import { api } from '../..';
import urls from '../urls';
export interface CountriesInterface {
  id: number | null | undefined;
  name: string;
  blocked: boolean;
  iso2Code: string;
  iso3Code: string;
  keyword: string;
  orderNumber: number | null | undefined;
  prefix: string;
  states: [];
}
function getItems() {
  return api.get(urls.countries.getAll).then(res => res.data);
}
const CountriesApi = {
  getItems
};
export default CountriesApi;