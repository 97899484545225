import moment from 'moment'

require('moment/locale/fi')

export function formatDateToFinnish(date: string) {
  if (!date) {
    return ''
  }
  moment.locale('fi')
  let formattedDate = moment(date).format('D. MMMM YYYY')
  formattedDate = formattedDate.replace(/(\w+)\s/, '$1kuuta ')

  return formattedDate
}
