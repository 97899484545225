import Typography from 'components/uiKit/typography';
import { globalData } from 'pages/_app';
import React from 'react';
import { textTranslated } from '.';
interface Props {
  group: string;
  translateKey: string;
  children: React.ReactElement;
}
export default function WrapperEditTranslation(props: Props) {
  const {
    group,
    translateKey: key,
    children
  } = props;
  const {
    editTranslations
  } = globalData;
  if (!editTranslations) {
    return children;
  }
  return <div key={`${editTranslations}-status`} style={{
    background: '#fff',
    position: 'relative',
    zIndex: 9999
  }} data-sentry-component="WrapperEditTranslation" data-sentry-source-file="wrapperEditTranslation.tsx">
            <Typography translateGroup={group} translateKey={key} style={{
      color: '#000 !important'
    }} data-sentry-element="Typography" data-sentry-source-file="wrapperEditTranslation.tsx" />
        </div>;
}