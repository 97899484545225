import React, { useContext, useState } from 'react';
import Link from 'components/customLink';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import { staticLobbyItem } from 'pages/api/static-lobbies';
import Image from 'next/image';
import styles from './styles.module.scss';
export default function LargePromoGameCard2(props: staticLobbyItem) {
  const {
    provider,
    name,
    displayProvider,
    displayProviderOnHover,
    displayName,
    displayNameOnHover,
    aspectRatio,
    backgroundColor,
    link,
    linkLabel,
    thumbnail,
    onHoverThumbnail,
    displayNameColor,
    displayNameOnHoverColor,
    displayProviderColor,
    displayProviderOnHoverColor,
    scaleThumbOnHover,
    changeThumbOnHover,
    displayCta,
    displayCtaOnHover,
    ctaBackgroundColor,
    ctaBackgroundColorOnHover,
    ctaColor,
    ctaColorOnHover,
    customLink
  } = props;
  const {
    state,
    appType
  } = useContext(GlobalContext);
  const [mouseIn, setMouseIn] = useState(false);
  const handleMouseEnter = () => setMouseIn(true);
  const handleMouseLeave = () => setMouseIn(false);
  return <Link href={link || customLink || ''} style={{
    width: '100%'
  }} data-sentry-element="Link" data-sentry-component="LargePromoGameCard2" data-sentry-source-file="index.tsx">
      <Grid verticalAlgin="stretch" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.wrapper} style={{
      aspectRatio: aspectRatio || '410 / 248',
      backgroundColor
    }} data-scale-on-hover={!!scaleThumbOnHover} wrap="nowrap" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid data-change-on-hover={!!(changeThumbOnHover && onHoverThumbnail)} className={styles.graph} style={{
        aspectRatio: aspectRatio || '410 / 248'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <div data-img-type="thumb" className={styles['wrapper-thumb']} style={{
          backgroundColor
        }}>
            <Image src={thumbnail} alt={name} quality={80} fill sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" style={{
            objectFit: 'cover'
          }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          </div>
          {changeThumbOnHover && onHoverThumbnail && <div data-img-type="thumb-hover" className={styles['wrapper-thumb']} style={{
          backgroundColor
        }}>
              <Image src={onHoverThumbnail || thumbnail} alt={name} quality={80} priority={mouseIn} fill sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" style={{
            opacity: mouseIn ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            objectFit: 'cover'
          }} />
            </div>}
        </Grid>

        {linkLabel && <div data-display-cta={!!displayCta} data-display-cta-hover={!!displayCtaOnHover} className={styles.cta} hidden={appType === 'mobile'}>
            <div>
              <button id={`${name}-cta`} data-btn-state="cta" type="button" style={{
            color: ctaColor,
            backgroundColor: ctaBackgroundColor,
            borderColor: ctaBackgroundColor
          }}>
                <Typography size="md" weight={500}>
                  {linkLabel}
                </Typography>
              </button>
              <button data-btn-state="cta-hover" type="button" style={{
            color: ctaColorOnHover,
            backgroundColor: ctaBackgroundColorOnHover,
            borderColor: ctaBackgroundColorOnHover
          }}>
                <Typography size="md" weight={500}>
                  {linkLabel}
                </Typography>
              </button>
            </div>
          </div>}
      </Grid>
    </Link>;
}