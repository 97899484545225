import gsap from 'gsap'

interface menuMobileAnimationProps {
  state: 'expand' | 'collapse' | 'full-expand',
  menu: HTMLElement,
}

function getContentHeight(menu: HTMLElement) {
  let totalHeight = 0
  const children = Array.from(menu.children)

  children.forEach((child) => {
    totalHeight += (child as HTMLElement).offsetHeight
  })

  return totalHeight
}

export function menuMobileAnimation({ state, menu }: menuMobileAnimationProps) {
  const durationMultiplier = 1.5 // Adjust duration as needed
  const bounceEase = 'elastic.out(1, 0.9)' // Adjust easing for bounce effect
  const maxMenuHeight = getContentHeight(menu)

  if (state === 'expand') {
    gsap.to(menu, { height: `${maxMenuHeight}px`, duration: durationMultiplier, ease: bounceEase })
  } else if (state === 'full-expand') {
    gsap.to(menu, { height: '100dvh', duration: durationMultiplier, ease: bounceEase })
  } else {
    gsap.timeline({
      defaults: { duration: durationMultiplier, ease: bounceEase },
    })
      .to(menu, {
        height: '66px', ease: bounceEase, width: '100dvw', paddingTop: '0rem',
      })
  }
}
