import Grid from 'components/uiKit/grid';
import React, { useContext } from 'react';
import AuthContext from 'context/auth';
import Typography from 'components/uiKit/typography';
import Link from 'next/link';
import Button from 'components/uiKit/buttons';
import GlobalContext from 'context/global';
import Balance from './balance';
import Links from './links';
interface Props {
  section: string;
  setSection: Function;
}
export default function HamburgerMenu(props: Props) {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    appType
  } = useContext(GlobalContext);
  return <Grid className="mb-10" data-sentry-element="Grid" data-sentry-component="HamburgerMenu" data-sentry-source-file="index.tsx">
      <Links {...props} data-sentry-element="Links" data-sentry-source-file="index.tsx" />
      {isAuthenticated && <Balance />}
      {(isAuthenticated || appType === 'desktop') && <Grid className="w-full">
          <Link href="/cashier/deposit" className="w-full">
            <Button block id="talleta-cta" color="yellow" size="md">
              <Typography translateGroup="global" translateKey="TALLETA" style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: '20px'
          }} weight={700} />
            </Button>
          </Link>
        </Grid>}
    </Grid>;
}