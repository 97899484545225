import PromoGameCard from 'components/cards/promoGameCard';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import React from 'react';
import { useQuery } from 'react-query';
import { parseGames } from 'utils/functions/games';
import { searchGames } from 'utils/services/api/requests/games';
import { staticLobbyItem } from 'pages/api/static-lobbies';
const gridSizes = [{
  sm: '50%',
  md: '33.33%',
  lg: '16.66%'
}, {
  sm: '100%',
  md: '66.66%',
  lg: '33.33%'
}, {
  sm: '100%',
  md: '66.66%',
  lg: '33.33%'
}].map(size => ({
  sm: `calc(${size.sm} - 0.5rem)`,
  md: `calc(${size.md} - 2rem / 3)`,
  lg: `calc(${size.lg} - 5rem / 6)`
}));
interface Props {
  filter: string;
  color?: 'var(--primary)' | '#fff';
}
export default function SearchGames({
  filter,
  color = '#fff'
}: Props) {
  const {
    appType
  } = React.useContext(GlobalContext);
  const isMobile = ['mobile', 'tablet'].includes(appType);
  const {
    data: filterResponse,
    isLoading
  } = useQuery(['games-search', filter], () => searchGames({
    search: filter,
    params: {
      numberOfGames: 50,
      mobile: isMobile
    }
  }), {
    enabled: !(filter.length < 2)
  });
  const originalGames = filterResponse?.map((item: any) => ({
    game: item
  })) || [];
  const parsedList = parseGames({
    originalGames,
    defaultSize: () => 1
  }).sort((a: staticLobbyItem, b: staticLobbyItem) => {
    const startWithFilter = (str: any) => str.name.toLowerCase().startsWith(filter);
    if (startWithFilter(a) && !startWithFilter(b)) {
      return -1;
    }
    if (!startWithFilter(a) && startWithFilter(b)) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
  if ((filter?.length ?? 0) < 2) {
    return <></>;
  }
  if (parsedList.length <= 0 && !isLoading) {
    return <>
        {appType === 'mobile' ? <Grid width="100%" verticalAlgin="center" horizontalAlgin="center" wrap="wrap" style={{
        color
      }}>
            <Grid verticalAlgin="center" horizontalAlgin="center" margin={['mt-5', 'mb-5']}>
              <Typography translateGroup="searchGame" weight={700} style={{
            fontSize: '1.5rem',
            textAlign: 'center',
            lineHeight: '1.45rem'
          }} translateKey="Pahus, emme löydä etsimääsi." />
            </Grid>
            <Grid verticalAlgin="center" horizontalAlgin="center">
              <Typography translateGroup="searchGame" style={{
            fontSize: '0.75rem',
            width: '100%',
            textAlign: 'center'
          }} translateKey="Mutta etsivä löytää." />
              <Typography translateGroup="searchGame" style={{
            fontSize: '0.75rem',
            width: '100%',
            textAlign: 'center'
          }} translateKey="Tarkista hakusanasi tai kokeile jotain toista." />
            </Grid>
          </Grid> : <Grid width="100%" verticalAlgin="center" horizontalAlgin="center" wrap="wrap">
            <Grid verticalAlgin="center" horizontalAlgin="center" margin={['mt-5', 'mb-5']}>
              <Typography translateGroup="searchGame" weight={700} style={{
            fontSize: '6rem',
            textAlign: 'center',
            lineHeight: '5.3rem'
          }} translateKey="Pahus, emme löydä etsimääsi." />
            </Grid>
            <Grid verticalAlgin="center" horizontalAlgin="center">
              <Typography translateGroup="searchGame" style={{
            fontSize: '1.5rem',
            width: '100%',
            textAlign: 'center'
          }} translateKey="Mutta etsivä löytää." />
              <Typography translateGroup="searchGame" style={{
            fontSize: '1.5rem',
            width: '100%',
            textAlign: 'center'
          }} translateKey="Tarkista hakusanasi tai kokeile jotain toista." />
            </Grid>
          </Grid>}
      </>;
  }
  return <Grid gap={appType === 'mobile' ? '0.5rem' : '1rem'} id="search" style={{
    overflowX: 'auto'
  }} data-sentry-element="Grid" data-sentry-component="SearchGames" data-sentry-source-file="index.tsx">
      {!isLoading && <Grid verticalAlgin="center" horizontalAlgin="center" style={{
      margin: '0 auto'
    }} padding={['pt-2', 'pb-2']}>
          <Typography translateGroup="search-area" color={appType === 'mobile' ? color : 'var(--primary)'} translateKey={`Löysimme haullasi ${parsedList.length} peliä`} size={appType === 'mobile' ? 'md' : 'xl'} className="!uppercase !font-extrabold xs:!mt-5 md:!mt-0" />
        </Grid>}
      <Grid padding={['pe-3', 'ps-3']} gap="1rem" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {parsedList.map((game, index) => {
        return <Grid key={index} responsiveWidth={gridSizes[game.size - 1]}>
              <PromoGameCard {...game} displayNameOnHover={false} displayName={false} displayProvider={false} />
            </Grid>;
      })}
      </Grid>
    </Grid>;
}