/* eslint-disable react/prop-types */
import React from 'react';
export default function LogoutIcon({
  width = 30,
  height = 30,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="LogoutIcon" data-sentry-source-file="logoutIcon.tsx">
      <g clipPath="url(#clip0_10327_6880)" data-sentry-element="g" data-sentry-source-file="logoutIcon.tsx">
        <path d="M5.625 28.125H16.875C17.3721 28.1244 17.8487 27.9267 18.2002 27.5752C18.5517 27.2237 18.7494 26.7471 18.75 26.25V23.4375H16.875V26.25H5.625V3.75H16.875V6.5625H18.75V3.75C18.7494 3.25289 18.5517 2.77631 18.2002 2.42481C17.8487 2.0733 17.3721 1.87557 16.875 1.875H5.625C5.12789 1.87557 4.65131 2.0733 4.29981 2.42481C3.9483 2.77631 3.75057 3.25289 3.75 3.75V26.25C3.75057 26.7471 3.9483 27.2237 4.29981 27.5752C4.65131 27.9267 5.12789 28.1244 5.625 28.125Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logoutIcon.tsx" />
        <path d="M19.2994 19.2994L22.6613 15.9375H9.375V14.0625H22.6613L19.2994 10.7006L20.625 9.375L26.25 15L20.625 20.625L19.2994 19.2994Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="logoutIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="logoutIcon.tsx">
        <clipPath id="clip0_10327_6880" data-sentry-element="clipPath" data-sentry-source-file="logoutIcon.tsx">
          <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="logoutIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
}