import { moveSizeTwoItemsUp } from '../arrays';
import { replaceDashesToHyphens } from '../strings';
import { CategoryI, GameParsedI, LobbyCategoryI, LobbyI } from './types';
interface parseLobbyGamesP {
  originalGames: any;
  defaultSize?: 1 | 2 | 3 | Function;
}
interface parseLobbyGamesFormat {
  originalGame: any;
  defaultSize?: 1 | 2 | 3 | Function;
}
export function getCategoryDisplayName(displayKeyword: string) {
  if (!displayKeyword) {
    return '';
  }
  return displayKeyword.replace('icon-left | ', '')?.replace('icon-right | ', '')?.replace('icon-only | ', '');
}
export function formatGame({
  originalGame = {},
  defaultSize: defaultSizeP
}: parseLobbyGamesFormat) {
  const defaultSize = typeof defaultSizeP === 'function' ? defaultSizeP(originalGame) || 1 : defaultSizeP || 1;
  const customGame: any = originalGame?.gameCustomization?.enabled ? originalGame.gameCustomization : undefined;
  if (customGame) {
    const {
      customLink,
      useOriginalThumbnail,
      thumbnail
    } = customGame;
    return {
      ...customGame,
      link: replaceDashesToHyphens(customLink),
      name: customGame.name ? customGame.name : originalGame?.keywordName,
      thumbnail: useOriginalThumbnail ? originalGame?.promotedThumbnail || originalGame?.thumbnailURL : thumbnail
    };
  }
  const aspectRatio = ['198 / 248', '410 / 248', '410 / 248'];
  return {
    aspectRatio: aspectRatio[defaultSize - 1],
    backgroundColor: '#EFEEEE',
    categoryId: 'search',
    ctaBackgroundColor: '#ffeb89',
    ctaBackgroundColorOnHover: '#ffeb89',
    ctaColor: '#4a26ff',
    ctaColorOnHover: '#4a26ff',
    displayCtaOnHover: true,
    displayName: true,
    displayNameColor: '#4a26ff',
    displayNameOnHover: true,
    displayNameOnHoverColor: '#4a26ff',
    displayProvider: true,
    displayProviderColor: '#4a26ff',
    displayProviderOnHover: true,
    displayProviderOnHoverColor: '#4a26ff',
    link: `/games/launch/${replaceDashesToHyphens(originalGame?.gameId)}`,
    linkLabel: 'PELLA',
    name: originalGame?.keywordName,
    provider: originalGame?.providerName,
    size: defaultSize,
    thumbnail: originalGame?.promotedThumbnail || originalGame?.thumbnailURL,
    scaleThumbOnHover: true,
    changeThumbOnHover: false,
    onHoverThumbnail: '/games/default-hover.png',
    id: originalGame?.id
  };
}
export function parseGames({
  originalGames = [],
  defaultSize: defaultSizeP
}: parseLobbyGamesP) {
  if (!originalGames.length) return [];
  const parsedData = originalGames?.map(({
    game
  }: any) => {
    return formatGame({
      originalGame: game,
      defaultSize: defaultSizeP
    });
  });
  const orderedData = moveSizeTwoItemsUp(parsedData);
  return orderedData;
}
export type parsedLobbyGamesI = Array<{
  category: {
    internalName: string;
    name: string;
    description: string;
    icon: string;
    games: Array<GameParsedI>;
    iconPosition: string;
  };
}>;
export function parseLobbyGames({
  originalGames
}: parseLobbyGamesP): parsedLobbyGamesI {
  const lobby: LobbyI = originalGames;
  const lCategories: Array<LobbyCategoryI> = [...(lobby?.lobbyCategories || [])];
  const parsedCategories: any = lCategories.map((lCategory: LobbyCategoryI) => {
    const parsedGames = parseGames({
      originalGames: lCategory.category.gameCategories
    });
    return {
      ...lCategory.category,
      games: parsedGames
    };
  });
  const parsedData = parsedCategories.map((cCategory: any) => {
    const games: Array<GameParsedI> = cCategory.games as any;
    const currentIconPositionValue = `${cCategory?.displayKeyword}`.includes('icon-left') ? 'icon-left' : `${cCategory?.displayKeyword}`.includes('icon-right') ? 'icon-right' : `${cCategory?.displayKeyword}`.includes('icon-only') ? 'icon-only' : '';
    return {
      category: {
        enabled: cCategory.enabled,
        internalName: cCategory.internalName,
        name: getCategoryDisplayName(cCategory.displayKeyword),
        description: cCategory.description,
        icon: cCategory.icon,
        iconPosition: currentIconPositionValue,
        games
      }
    };
  });
  return parsedData;
}