import AuthContext from 'context/auth';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import DepositLimitApi from 'utils/services/api/requests/depositLimit';
import { getProfile } from 'utils/services/api/requests/userEnv';
import { formatCurrency } from 'utils/functions/wallet';
import Typography from 'components/uiKit/typography';
import { toastError, toastSuccess } from 'utils/functions/notifications';
import validateForm from './formValidation';
import { DepositLimitInterface } from '../../types';
export function useDepositHook() {
  const [errors, setErrors] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const {
    token,
    isAuthenticated
  } = useContext(AuthContext);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const {
    data: userAccount,
    isLoading: isLoadingUser
  } = useQuery(['profile', token], getProfile, {
    enabled: !!isAuthenticated,
    staleTime: 12000
  });
  const {
    data,
    isLoading: isLoadingDeposit,
    refetch: refetchDeposit
  } = useQuery(['deposit-limitis', token], async () => {
    const response = await DepositLimitApi.getDepositLimit();
    if (response === false) {
      setShouldFetchData(false);
      return {
        limitAmount: ''
      };
    }
    return response;
  }, {
    enabled: !!isAuthenticated && shouldFetchData
  });
  const [formData, setFormData] = useState<DepositLimitInterface>(data || {
    frequency: undefined
  });
  const [prices, setPrices] = useState({
    limitAmount: '',
    totalAmount: '',
    futureLimitAmount: ''
  });
  useEffect(() => {
    if (data) {
      setFormData(data);
      setPrices({
        limitAmount: data?.limitAmount ? formatCurrency(parseFloat(data.limitAmount), userAccount.userProfile.currency) : '',
        totalAmount: data?.totalAmount ? formatCurrency(parseFloat(data.totalAmount), userAccount.userProfile.currency) : formatCurrency(0, userAccount.userProfile.currency),
        futureLimitAmount: data?.futureLimitAmount ? formatCurrency(parseFloat(data.futureLimitAmount), userAccount.userProfile.currency) : ''
      });
    }
  }, [data]);
  function updateField(field: string, value: string) {
    setErrors((d: any) => {
      return {
        ...d,
        [field]: ''
      };
    });
    setFormData(current => ({
      ...current,
      [field]: value
    }));
  }
  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setLoading(true);
    const validation = await validateForm(formData);
    if (validation.count) {
      setErrors((d: any) => {
        return {
          ...d,
          ...validation
        };
      });
      toastError(<Typography translateGroup="responsible-deposit-limits" translateKey="something-went-wrong" />);
      setLoading(false);
    } else {
      const {
        limitAmount,
        frequency,
        created
      } = formData;
      DepositLimitApi.submitForm({
        created,
        frequency,
        limitAmount: Number(limitAmount) || 0
      }).then(res => {
        toastSuccess(<Typography translateGroup="responsible-deposit-limits" translateKey="deposit-limits-success" />);
        refetchDeposit();
      }).catch(err => {
        toastError(<Typography translateGroup="responsible-deposit-limits" translateKey="something-went-wrong" />);
      });
      setLoading(false);
    }
  }
  function handlerChangePrice(event: ChangeEvent<HTMLInputElement>) {
    let rawValue = event.target.value.replace(/[^0-9.]/g, '');
    const decimalIndex = rawValue.indexOf('.');
    if (decimalIndex !== -1) {
      rawValue = rawValue.slice(0, decimalIndex + 3);
    }
    setPrices({
      ...prices,
      [event.target.name]: rawValue
    });
    updateField(event.target.name, rawValue);
  }
  function handlerBlurPrice(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value) {
      setPrices({
        ...prices,
        [event.target.name]: ''
      });
    } else {
      const rawValue = event.target.value.replace(/[^0-9.]/g, '');
      const formattedValue = formatCurrency(parseFloat(rawValue), userAccount.userProfile.currency);
      setPrices({
        ...prices,
        [event.target.name]: formattedValue
      });
    }
  }
  return {
    isLoading: isLoadingDeposit || isLoadingUser,
    loading,
    formData,
    updateField,
    errors,
    prices,
    setPrices,
    userAccount,
    handleSubmit,
    handlerChangePrice,
    handlerBlurPrice
  };
}