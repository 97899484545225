import ExpandCollapseCard from 'components/cards/expandCollapseCard';
import { SectionProps } from 'components/navbar/mobile/loggedIn';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import History from 'layouts/profile/sections/tabs/history';
export default function HistorySection({
  state,
  handleState
}: SectionProps) {
  return <ExpandCollapseCard color="white" value={state} header={<Grid horizontalAlgin="center" onClick={handleState}>
          <Typography translateGroup="history-section" translateKey="HISTORY" weight={600} />
        </Grid>} data-sentry-element="ExpandCollapseCard" data-sentry-component="HistorySection" data-sentry-source-file="index.tsx">
      <History data-sentry-element="History" data-sentry-source-file="index.tsx" />
    </ExpandCollapseCard>;
}