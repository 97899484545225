import Loading from 'assets/loading';
import Grid from 'components/uiKit/grid';
import AuthContext from 'context/auth';
import { useRouter } from 'next/router';
import React from 'react';
import { useQuery } from 'react-query';
import { formatCurrencySymbol } from 'utils/functions/wallet';
import { getProfile } from 'utils/services/api/requests/userEnv';
import { getBalance } from 'utils/services/api/requests/wallet';
interface UserBalanceOnlyRealProps {
  refetchInterval?: number | false;
}
export default function UserBalanceOnlyReal({
  refetchInterval
}: UserBalanceOnlyRealProps) {
  const {
    pathname
  } = useRouter();
  const {
    isAuthenticated,
    token
  } = React.useContext(AuthContext);
  const {
    data: userProfile
  } = useQuery(['profile', token], getProfile, {
    enabled: !!isAuthenticated,
    staleTime: 12000
  });
  const {
    data: balance,
    isLoading
  } = useQuery(['user-balance', token], getBalance, {
    enabled: !!(!!isAuthenticated && !!userProfile),
    refetchInterval: refetchInterval === false ? false : refetchInterval || 2000
  });
  if (isLoading) {
    return <Grid verticalAlgin="center" horizontalAlgin="center">
        <Loading />
      </Grid>;
  }
  const totalBalance = balance?.reduce((acc: number, account: any) => acc + account.balance, 0);
  if (pathname === '/games/launch/[gameName]') {
    return <></>;
  }
  return <>
      {formatCurrencySymbol(totalBalance || 0, userProfile?.userProfile?.currency, true)}
    </>;
}