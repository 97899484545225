import Typography from 'components/uiKit/typography';
import React, { ChangeEventHandler, HTMLInputTypeAttribute, useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { globalData } from 'pages/_app';
import Grid from 'components/uiKit/grid';
import { typographySize } from 'components/uiKit/typography/types';
import Checkbox from './variants/checkbox';
import styles from './input.group.module.scss';
import TextAreaGroup from './variants/textArea';
import { InputFieldDate } from './variants/date';
export interface uiKitInputProps {
  label: string | undefined | any;
  value?: string | ReadonlyArray<string> | number | undefined | boolean;
  name: string;
  id: string;
  inputType?: HTMLInputTypeAttribute | 'textarea';
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  status?: 'error' | 'success' | 'warning' | 'info' | '';
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  feedback?: React.ReactNode;
  styles?: React.CSSProperties;
  noEditTranslation?: boolean;
  fontSize?: typographySize | Array<typographySize>;
  disableLabelAnimated?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  readOnly?: any;
}
function Label({
  label
}: {
  label: string | React.ReactNode;
}) {
  return typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label} size="normal" /> : <>{label}</>;
}
function PasswordToggleIcon({
  inputType,
  setInputType
}: {
  inputType: string;
  setInputType: React.Dispatch<React.SetStateAction<string>>;
}) {
  return <div className={styles['eye-icon']} hidden={inputType !== 'password'} data-sentry-component="PasswordToggleIcon" data-sentry-source-file="index.tsx">
      {inputType === 'password' ? <BsEye onClick={() => setInputType('text')} /> : <BsEyeSlash onClick={() => setInputType('password')} />}
    </div>;
}
function Feedback({
  feedback
}: {
  feedback: React.ReactNode;
}) {
  return <div className={styles.feedback} data-sentry-component="Feedback" data-sentry-source-file="index.tsx">
      <Typography size="xsm" data-sentry-element="Typography" data-sentry-source-file="index.tsx">{feedback}</Typography>
    </div>;
}
function InputField({
  inputType,
  handleChange,
  handleClick,
  inputProps,
  value,
  name,
  id,
  label,
  readOnly,
  onBlur
}: any) {
  return <input value={value as any} onChange={handleChange} onBlur={onBlur} disabled={readOnly} name={name} id={id} {...inputProps} onClick={handleClick} type={inputType || inputProps?.type || 'text'} placeholder={label} data-sentry-component="InputField" data-sentry-source-file="index.tsx" />;
}
export default function InputGroupNew(props: uiKitInputProps) {
  const {
    label,
    value,
    name,
    id,
    inputType: inputTypeProps,
    onChange,
    status,
    inputProps,
    feedback,
    styles: stylesProps,
    noEditTranslation,
    fontSize,
    onBlur,
    readOnly
  } = props;
  const [inputType, setInputType] = useState(inputTypeProps || 'text');
  const {
    editTranslations
  } = globalData;
  const formatDateString = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate() + 1}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.type === 'date') {
      event.target.value = formatDateString(event.target.value);
    }
    if (onChange) {
      onChange(event);
    }
  };
  const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (inputType === 'date' || inputProps?.type === 'date') {
      const {
        target
      }: any = e as any;
      if (!inputProps?.readOnly) {
        target?.showPicker();
      }
    }
    if (editTranslations && inputProps?.onClick) {
      if (e?.target === e?.currentTarget) {
        inputProps?.onClick(e);
      }
    } else if (inputProps?.onClick) {
      inputProps?.onClick(e);
    }
  };
  if (inputType === 'checkbox') {
    return <Checkbox {...props} />;
  }
  if (inputType === 'textarea') {
    return <TextAreaGroup {...props as any} />;
  }
  if (inputType === 'date') {
    return <InputFieldDate {...props as any} />;
  }
  return <>
      {editTranslations && !noEditTranslation ? <Grid>
          <Grid className={styles['animated-label']}>
            <Label label={label} />
          </Grid>
          <InputField inputType={inputType} handleChange={handleChange} handleClick={handleClick} inputProps={inputProps} value={value} name={name} id={id} label={label} readOnly={readOnly} onBlur={onBlur} />
          <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
        ...stylesProps
      }}>
            {feedback && <Feedback feedback={feedback} />}
          </div>
        </Grid> : <div data-status={status} data-font-size={fontSize || 'sm'} className={styles['input-group-wrapper']} style={{
      ...stylesProps
    }}>
          <label data-filled={!!value}>

              <InputField inputType={inputType} handleChange={handleChange} handleClick={handleClick} inputProps={inputProps} value={value} name={name} id={id} label={label} readOnly={readOnly} onBlur={onBlur} />

            <div data-ready-only={readOnly} id="animated-label-value" className={styles['animated-label']}>
              <Label label={label} />
            </div>
            <PasswordToggleIcon inputType={inputType} setInputType={setInputType} />
          </label>
          {feedback && <Feedback feedback={feedback} />}
        </div>}
    </>;
}