import React from 'react';
import dynamic from 'next/dynamic';
import Grid from 'components/uiKit/grid';
import XpBonuses from './xpBonus';
import FreeSpinBonuses from './freeSpinBonuses';
const TuohirullaList = dynamic(() => import('./tuohirulla'));
export default function BonusesSection() {
  return <Grid id="bonus-section-container" gap="2rem" data-sentry-element="Grid" data-sentry-component="BonusesSection" data-sentry-source-file="index.tsx">
            {/* Temporary until the flow gets fixed */}
            {/* <VerifyEmailWarn /> */}
            {/* <VerifyPhoneWarn /> */}
            <FreeSpinBonuses data-sentry-element="FreeSpinBonuses" data-sentry-source-file="index.tsx" />
            <TuohirullaList data-sentry-element="TuohirullaList" data-sentry-source-file="index.tsx" />
            <XpBonuses data-sentry-element="XpBonuses" data-sentry-source-file="index.tsx" />

            {/* This is the missions section, not sure if it will be used */}
            {/* <MissionsBonuses /> */}
        </Grid>;
}