import { dataTableColumnType } from 'components/uiKit/dataTable';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import moment from 'moment';
import { formatCurrency } from 'utils/functions/wallet';
export const columns = (): Array<dataTableColumnType> => {
  return [{
    key: 'date',
    label: 'Päivämäärä',
    uniqueId: 'date',
    filter: true,
    render: (value: string) => {
      return <Grid gap="0.5rem">
            <Typography>{moment(value).format('DD/MM/YYYY')}</Typography>
            <Typography weight={600}>
              {moment(value).format('HH:mm')}
            </Typography>
          </Grid>;
    }
  }, {
    key: 'type',
    label: 'Tapahtuma',
    uniqueId: 'type',
    filter: true,
    render: (value: any) => {
      return <Typography translateGroup="table-columns" translateKey={value} />;
    }
  }, {
    key: 'gameName',
    label: 'origin',
    uniqueId: 'game-name',
    filter: true
  }, {
    key: 'amount',
    label: 'Summa',
    uniqueId: 'amount',
    filter: true,
    style: {
      width: '85vw'
    },
    render: (value: any, {
      type
    }: any) => {
      return <Typography weight={600} color={['GAME_SPIN'].includes(type) ? 'var(--danger)' : ['GAME_WIN'].includes(type) ? 'var(--success)' : '#000'}>
            {formatCurrency(value, 'EUR')}
          </Typography>;
    }
  }];
};