import { api } from '../..'
import urls from '../urls'

export interface campaignsI {
    id: number;
    internalName: string;
    externalName: string;
    enabled: boolean;
    startDate: string;
    endDate: string;
    loggedInOnly: boolean;
    imagePreview: string;
    titlePreview: string;
    descriptionPreview: string;
    imageDetail: string;
    titleDetail: string;
    descriptionDetail: string;
    ordinal: number;
}
async function getItems() {
    const res = await api.get(urls.campaigns.getAll)
    return res.data
}
async function getCampaignByExternalName(externalName: string) {
    const res = await api.get(urls.campaigns.getByExternalName.replace('{{externalName}}', externalName))
    return res?.data
}

const CampaignsApi = {
    getItems,
    getCampaignByExternalName,
}

export default CampaignsApi
