import React from 'react';
export interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}
export default function MenuIcon({
  size,
  ...nProps
}: Props) {
  const aspectRatio = 30 / 24;
  return <svg xmlns="http://www.w3.org/2000/svg" width={size || 30} height={size ? size / aspectRatio : 24} fill="none" viewBox="0 0 30 24" {...nProps} data-sentry-element="svg" data-sentry-component="MenuIcon" data-sentry-source-file="menuIcon.tsx">
      <path fill="#4A26FF" d="M0 24v-2.4h30V24H0zM0 13.2v-2.4h30v2.4H0zM0 2.4V0h30v2.4H0z" data-sentry-element="path" data-sentry-source-file="menuIcon.tsx" />
    </svg>;
}