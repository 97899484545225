/* eslint-disable react/prop-types */
import React from 'react';
export default function KasinoIcon({
  width = 30,
  height = 24,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="KasinoIcon" data-sentry-source-file="kasinoIcon.tsx">
      <g clipPath="url(#clip0_10401_6857)" data-sentry-element="g" data-sentry-source-file="kasinoIcon.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.73856 24H12.9605V22.3647H9.73856V24ZM15.3972 22.3647V24H18.6446V22.3647H15.3972ZM4.06055 24H7.30789V22.3647H4.06055V24Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="kasinoIcon.tsx" />
        <path d="M10.973 16.7952L9.40573 16.1064L11.4581 11.2359H8.92773V9.49365H14.0484L10.973 16.7952Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="kasinoIcon.tsx" />
        <path d="M16.7425 16.7952L15.1763 16.1064L17.2276 11.2359H14.6973V9.49365H19.818L16.7425 16.7952Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="kasinoIcon.tsx" />
        <path d="M5.23077 16.7952L3.66355 16.1064L5.71487 11.2359H3.18555V9.49365H8.30623L5.23077 16.7952Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="kasinoIcon.tsx" />
        <path d="M30 3.30791C30 1.48149 28.5446 0 26.7506 0C24.9566 0 23.5013 1.48046 23.5013 3.30791C23.5013 4.84135 24.5254 6.12961 25.9157 6.50465V13.2316H22.6937V8.27081C22.6937 6.89944 21.6055 5.78676 20.2621 5.78676H2.43169C1.08821 5.78676 0 6.89944 0 8.27081V17.3883C0 18.7607 1.08821 19.8724 2.43169 19.8724H20.2621C21.6055 19.8724 22.6937 18.7607 22.6937 17.3883V14.8877H27.5368V6.51712C28.9515 6.15869 30 4.85797 30 3.30791ZM21.0726 17.3873C21.0726 17.8434 20.7085 18.2153 20.2621 18.2153H2.43169C1.98522 18.2153 1.62113 17.8434 1.62113 17.3873V8.26977C1.62113 7.81369 1.98522 7.44176 2.43169 7.44176H20.2621C20.7085 7.44176 21.0726 7.81369 21.0726 8.26977V17.3873ZM26.7506 4.95875C25.8526 4.95875 25.1224 4.218 25.1224 3.30687C25.1224 2.39574 25.8526 1.65499 26.7506 1.65499C27.6487 1.65499 28.3789 2.39574 28.3789 3.30687C28.3789 4.218 27.6487 4.95875 26.7506 4.95875Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="kasinoIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="kasinoIcon.tsx">
      <clipPath id="clip0_10401_6857" data-sentry-element="clipPath" data-sentry-source-file="kasinoIcon.tsx">
        <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="kasinoIcon.tsx" />
      </clipPath>
      </defs>
    </svg>;
}