/* eslint-disable react/prop-types */
import React from 'react';
export default function WithdrawMenuIcon({
  width = 29,
  height = 22,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="WithdrawMenuIcon" data-sentry-source-file="withdrawMenuIcon.tsx">
      <g clipPath="url(#clip0_10327_6894)" data-sentry-element="g" data-sentry-source-file="withdrawMenuIcon.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7178 8.97754V15.4888L11.515 12.6971L10.4902 13.861L14.5247 18.7445L18.5592 13.861L17.4619 12.7785L15.3316 15.4888V8.97754H13.7178Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="withdrawMenuIcon.tsx" />
        <path d="M24.909 -0.000161678H4.09905C1.83166 -0.000161678 0 1.84742 0 4.1345V8.89588C0 11.1586 1.79939 12.9898 4.0345 13.0224V18.7361C4.0345 20.9825 5.03506 21.9917 7.2621 21.9917H21.7863C24.0134 21.9917 25.0139 20.9825 25.0139 18.7361V13.0143C27.2248 12.9573 29 11.1341 29 8.88774V4.12636C29 1.83928 27.1683 -0.00830078 24.9009 -0.00830078L24.909 -0.000161678ZM23.4001 18.7442C23.4001 20.0953 23.1258 20.372 21.7863 20.372H7.2621C5.92265 20.372 5.6483 20.0953 5.6483 18.7442V7.34945H23.4001V18.7442ZM27.1522 8.89588C27.1522 10.1086 26.2001 11.0934 25.0139 11.1504V5.72163H4.0345V11.1586C2.82415 11.126 1.85587 10.1249 1.85587 8.90402V4.14264C1.85587 2.89736 2.8645 1.87997 4.09905 1.87997H24.909C26.1436 1.87997 27.1522 2.89736 27.1522 4.14264V8.90402V8.89588Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="withdrawMenuIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="withdrawMenuIcon.tsx">
        <clipPath id="clip0_10327_6894" data-sentry-element="clipPath" data-sentry-source-file="withdrawMenuIcon.tsx">
          <rect width={width} height={height} fill="currentColor" data-sentry-element="rect" data-sentry-source-file="withdrawMenuIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
}