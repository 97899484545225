import Loading from 'assets/loading';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import TypeButton from 'components/uiKit/inputs/TypeButton';
import Typography from 'components/uiKit/typography';
import moment from 'moment';
import { AiOutlineSave } from 'react-icons/ai';
import { useDepositHook } from './useDepositHook';
const optionData = [{
  value: 'DAY',
  label: '24 tuntia'
}, {
  value: 'WEEK',
  label: 'Yksi viikko'
}, {
  value: 'MONTH',
  label: '30 päivää'
}];
export default function DepositTabContent() {
  const {
    formData,
    updateField,
    errors,
    prices,
    handleSubmit,
    isLoading,
    loading,
    handlerChangePrice,
    handlerBlurPrice
  } = useDepositHook();
  if (isLoading) {
    return <Grid horizontalAlgin="center" verticalAlgin="center">
        <Loading />
      </Grid>;
  }
  return <Grid responsiveWidth={{
    sm: 100
  }} horizontalAlgin="center" data-sentry-element="Grid" data-sentry-component="DepositTabContent" data-sentry-source-file="index.tsx">
      <form lang="fi" onSubmit={handleSubmit} style={{
      width: '100%'
    }}>
        <Grid style={{
        flexDirection: 'column'
      }} verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid verticalAlgin="flex-end" gap="1rem" responsiveWidth={{
          sm: 100,
          md: '50%'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Grid responsiveWidth={{
            sm: 100,
            xl: 'calc(50% - 0.5rem)'
          }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Typography translateGroup="reward-step-selector" translateKey="frequency" weight={600} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
              </Grid>
              <TypeButton gridProps={{
              gap: '0.5rem'
            }} name="frequency" options={optionData} value={formData.frequency} onChange={({
              target
            }) => {
              updateField(target.name, target.value);
            }} feedback={errors?.frequency} status={errors?.frequency && 'error'} data-sentry-element="TypeButton" data-sentry-source-file="index.tsx" />
            </Grid>
            {formData?.applyAfter && <Grid responsiveWidth={{
            sm: 100,
            xl: 'calc(50% - 0.5rem)'
          }}>
                <InputGroup id="applyAfter" name="applyAfter" label="applyAfter" inputProps={{
              readOnly: true
            }} value={formData?.applyAfter ? moment(new Date(formData?.applyAfter)).format('DD-MM-YYYY HH:mm:ss') : ''} />
              </Grid>}
            <Grid gap="0.5rem" responsiveWidth={{
            sm: 100,
            xl: 'calc(50% - 0.5rem)'
          }} horizontalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              <InputGroup id="limitAmount" name="limitAmount" label="limitAmount" inputType="text" value={prices?.limitAmount || ''} onChange={event => handlerChangePrice(event)} onBlur={event => handlerBlurPrice(event)} feedback={errors?.limitAmount} status={errors?.limitAmount && 'error'} data-sentry-element="InputGroup" data-sentry-source-file="index.tsx" />
            </Grid>

            {formData?.futureFrequency && <Grid responsiveWidth={{
            sm: 100,
            md: 'calc(50% - 0.25rem)'
          }}>
                <Grid>
                  <Typography translateGroup="reward-step-selector" translateKey="futureFrequency" weight={600} />
                </Grid>
                <TypeButton gridProps={{
              gap: '0.5rem'
            }} name="futureFrequency" readOnly options={optionData} value={formData.futureFrequency} onChange={({
              target
            }) => {
              updateField(target.name, target.value);
            }} feedback={errors?.futureFrequency} status={errors?.futureFrequency && 'error'} />
              </Grid>}
            {prices?.futureLimitAmount && <Grid gap="0.5rem" responsiveWidth={{
            sm: 100,
            md: 'calc(50% - 0.25rem)'
          }} horizontalAlgin="center">
                <InputGroup id="futureLimitAmount" name="futureLimitAmount" label="futureLimitAmount" inputType="text" inputProps={{
              readOnly: true
            }} value={prices?.futureLimitAmount || ''} onChange={event => handlerChangePrice(event)} onBlur={event => handlerBlurPrice(event)} feedback={errors?.futureLimitAmount} status={errors?.futureLimitAmount && 'error'} />
              </Grid>}
          </Grid>
          <Grid margin={['mt-3']} horizontalAlgin="flex-end" responsiveWidth={{
          sm: 100,
          md: '50%'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Button id="profile-update-submit" type="submit" color="primary" disabled={loading} data-sentry-element="Button" data-sentry-source-file="index.tsx">
              <Grid padding={['ps-3', 'pe-3']} gap="0.5rem" horizontalAlgin="center" verticalAlgin="center" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                {loading ? <Loading /> : <Grid gap="0.5rem">
                    <AiOutlineSave />
                    <Typography translateGroup="global" translateKey="save" weight={700} />
                  </Grid>}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>;
}