import { LevelUp } from 'components/messages/LevelUp';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import { useBonuses } from 'utils/customHooks/useBonuses';
import { useProfile } from 'utils/customHooks/useProfile';
import useXpBonusProgress from 'utils/customHooks/useXpProgress';
import { toastLevelUp } from 'utils/functions/notifications';
const XP_LEVEL_PREFIX = 'XP_LEVEL_';
export const TuohirullaNotification: FC = () => {
  const {
    push
  } = useRouter();
  const {
    bonuses
  } = useBonuses();
  const {
    profile
  } = useProfile();
  const {
    data
  } = useXpBonusProgress();
  const isLevelUp = (tierLevel: string) => {
    if (!profile?.id) {
      return false;
    }
    const storageKey = [XP_LEVEL_PREFIX, profile.id].join('_');
    const currentLevel = localStorage.getItem(storageKey);
    if (!currentLevel) {
      localStorage.setItem(storageKey, tierLevel);
      return false;
    }
    if (currentLevel !== tierLevel) {
      localStorage.setItem(storageKey, tierLevel);
      return true;
    }
    return false;
  };
  useEffect(() => {
    const tierLevel = data?.tierLevel;
    if (!tierLevel) {
      return;
    }
    const isShowLevelUpMsg = isLevelUp(`${data?.tierLevel}`);
    if (isShowLevelUpMsg && bonuses.length) {
      toastLevelUp(<LevelUp onClick={() => {
        const spinCode = bonuses[0].code;
        if (spinCode) {
          push(`/tuohirulla/${spinCode}`);
        }
      }} />);
    }
  }, [data?.tierLevel]);
  return null;
};