/* eslint-disable react/no-danger */
import React from 'react';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import Link from 'components/customLink';
import Button from 'components/uiKit/buttons';
import Card from '../card';
interface Props {
  title: string;
  description: string;
  imageUrl: string;
  externalName: string;
}
export default function CampaignPreviewCard(props: Props) {
  const {
    title,
    description,
    imageUrl,
    externalName
  } = props;
  const isTuohirullaCampaign = externalName.toLowerCase() === 'tuohirulla';
  return <Card color="white" data-sentry-element="Card" data-sentry-component="CampaignPreviewCard" data-sentry-source-file="index.tsx">
            <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <img src={imageUrl} alt={title} width="100%" height="auto" style={{
        borderRadius: '10pt'
      }} />
            </Grid>
           <Grid gap="0.5rem" padding={['ps-3', 'pe-3']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                    <Typography weight={600} size="md" translateGroup="CampaignPreviewCard" translateKey={title} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
                </Grid>
                <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                    <section dangerouslySetInnerHTML={{
          __html: description
        }} style={{
          fontSize: 'small'
        }} />
                </Grid>
           </Grid>
            <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Link href={isTuohirullaCampaign ? '/tuohirulla' : `/kampanjat/${externalName}`} style={{
        width: '100%'
      }} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Button id="campaign-preview-cta" block color="yellow" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                        <Typography translateGroup="campaign-preview" translateKey="KLIKKAA TÄSTÄ" weight={700} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
                    </Button>
                </Link>
            </Grid>
        </Card>;
}