import Loading from 'assets/loading';
import CountrySelector from 'components/selectors/newCountry';
import PhoneNumberInput from 'components/selectors/newPhoneNumber';
import { prefixIso2Code } from 'components/selectors/phoneNumber/prefixIso2';
import InfoHover from 'components/uiKit/InfoHover';
import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import { useContext } from 'react';
import EmailInput from 'components/selectors/email';
import InputGroupNew from 'components/uiKit/inputs/inputNew';
import GlobalContext from 'context/global';
import Toggle from 'components/uiKit/inputs/Toggle';
import styles from './profile.form.module.scss';
import { useProfileHook } from './useProfileHook';
export default function ProfileForm() {
  const {
    appType
  } = useContext(GlobalContext);
  const {
    formData,
    countryInfo,
    updateField,
    handleSubmit,
    errors,
    isLoading,
    loading,
    handlerChangePhone,
    updateFieldToogle,
    profileValidationInfo
  } = useProfileHook();
  if (isLoading) {
    return <Grid verticalAlgin="center" horizontalAlgin="center">
        <Loading />
      </Grid>;
  }
  return <form lang="fi" className={styles.form} onSubmit={handleSubmit} data-sentry-component="ProfileForm" data-sentry-source-file="index.tsx">
      <Grid gap="1.5rem" verticalAlgin="flex-start" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid responsiveWidth={{
        sm: 100,
        md: 'calc(50% - 0.75rem)'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <InputGroupNew id="firstName" name="firstName" label="firstName" feedback={errors?.firstName} status={errors?.firstName && 'error'} value={`${formData?.firstName} ${formData?.lastName}` || ''} inputProps={{
          readOnly: true
        }} onChange={({
          target
        }) => {
          updateField(target.name, target.value);
        }} data-sentry-element="InputGroupNew" data-sentry-source-file="index.tsx" />
        </Grid>
        <Grid responsiveWidth={{
        sm: 100,
        md: 'calc(50% - 0.75rem)'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <InputGroupNew id="address1" name="address1" label="address1" feedback={errors?.address1} readOnly status={errors?.address1 && 'error'} value={formData?.address1 || ''} onChange={({
          target
        }) => {
          updateField(target.name, target.value);
        }} data-sentry-element="InputGroupNew" data-sentry-source-file="index.tsx" />
        </Grid>
        <Grid responsiveWidth={{
        sm: 100,
        md: 'calc(50% - 0.75rem)'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <PhoneNumberInput mobileNumber={formData?.mobileNumber || ''} mobilePrefix={formData?.mobilePrefix || ''} readOnly={!!profileValidationInfo?.phoneNumberVerified} updateField={updateField} label="phoneNumber" data-sentry-element="PhoneNumberInput" data-sentry-source-file="index.tsx" />
          {!countryInfo?.phoneNumberRequired ? <></> : formData?.mobileNumberChecked ? <InfoHover style={{
          left: 0
        }} content={<Typography translateGroup="profile" translateKey="verified-number-info" weight={600} />} /> : <InfoHover style={{
          left: 0
        }} content={<Typography translateGroup="profile" translateKey="verify-number-warn" weight={600} />} />}
        </Grid>
        <Grid responsiveWidth={{
        sm: 100,
        md: 'calc(50% - 0.75rem)'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <InputGroupNew id="city" name="city" label="kaupunki" feedback={errors?.city} status={errors?.city && 'error'} value={formData?.city || ''} readOnly onChange={({
          target
        }) => {
          updateField(target.name, target.value);
        }} styles={{
          marginTop: '-10px'
        }} data-sentry-element="InputGroupNew" data-sentry-source-file="index.tsx" />
        </Grid>
        <Grid responsiveWidth={{
        sm: 100,
        md: 'calc(50% - 0.75rem)'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <EmailInput id="email" name="email" label="email" feedback={errors?.email} status={errors?.email && 'error'} value={formData?.email || ''} onChange={({
          target
        }: any) => {
          updateField(target.name, target.value);
        }} inputProps={{
          readOnly: profileValidationInfo?.emailVerified
        }} data-sentry-element="EmailInput" data-sentry-source-file="index.tsx" />
        </Grid>
        <Grid gap="0.75rem" verticalAlgin="flex-end" responsiveWidth={{
        sm: 100,
        md: 'calc(50% - 0.75rem)'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Toggle id="emailMarketing" name="emailMarketing" label="emailMarketing" value={formData?.emailMarketing || false} onChange={({
          target
        }) => updateFieldToogle(target.name, target.value)} data-sentry-element="Toggle" data-sentry-source-file="index.tsx" />
          <Toggle id="smsMarketing" name="smsMarketing" label="smsMarketing" value={formData?.smsMarketing || false} onChange={({
          target
        }) => updateFieldToogle(target.name, target.value)} data-sentry-element="Toggle" data-sentry-source-file="index.tsx" />
        </Grid>
        <Grid horizontalAlgin="flex-end" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid responsiveWidth={{
          sm: 100,
          md: 'calc(50% - 0.75rem)'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <Button id="profile-update-submit" type="submit" color="yellow" style={{
            width: '100%'
          }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
              <Grid gap="0.5rem" horizontalAlgin="center" verticalAlgin="center" width="100%" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                {loading ? <Loading /> : <Grid gap="0.5rem" width="100%" horizontalAlgin="center" verticalAlgin="center">
                    <Typography translateGroup="global" translateKey="PÄIVITÄ" style={{
                  fontSize: '1.25rem'
                }} weight={700} />
                  </Grid>}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>;
}