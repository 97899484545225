import React from 'react';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import Button from 'components/uiKit/buttons';
import Link from 'components/customLink';
import { replaceDashesToHyphens } from 'utils/functions/strings';
import styles from './styles.module.scss';
interface Props {
  count: number;
  gameName: string;
  background: string;
  gameCode: string;
}
export default function ActiveFreeSpinsCard(props: Props) {
  const {
    count,
    gameName,
    background,
    gameCode
  } = props;
  return <Grid hidden={!count} className={styles.wrapper} verticalAlgin="space-between" padding={['p-3']} gap="0.5rem" style={{
    backgroundImage: background ? `url(${background})` : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: '100%',
    aspectRatio: '312 / 178'
  }} data-sentry-element="Grid" data-sentry-component="ActiveFreeSpinsCard" data-sentry-source-file="index.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Typography style={{
          fontSize: '2rem'
        }} weight={800} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {`${count || 0} X`}
          </Typography>
        </Grid>
        <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Typography weight={800} style={{
          textTransform: 'uppercase',
          fontSize: '1rem'
        }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            {gameName}
          </Typography>
        </Grid>
      </Grid>
      <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Link href={`/games/launch/${replaceDashesToHyphens(gameCode)}`} style={{
        width: '100%'
      }} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Button id="free-spin-cta" color="yellow" style={{
          maxWidth: '172px',
          width: '100%'
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <Typography translateGroup="free-spin-active-card" translateKey="PELAA" size="md" style={{
            padding: '0 2rem'
          }} weight={700} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
          </Button>
        </Link>
      </Grid>
    </Grid>;
}