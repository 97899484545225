import Grid from 'components/uiKit/grid';
import { useState } from 'react';
import { Pagination } from 'components/pagination';
import MobileHistoryCard from './mobileHistoryCard';
export function ListMobileHistory({
  sanitizedFilter
}: any) {
  const [dataPage, setDataPage] = useState(sanitizedFilter);
  const handlerSetFilter = (newDate: any) => {
    setDataPage(newDate);
  };
  return <>
      <Grid gap="1rem" style={{
      maxHeight: '50vh',
      overflowY: 'auto'
    }} padding={['pe-2', 'ps-2']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {dataPage.map((result: any) => {
        return <MobileHistoryCard {...result as any} key={`${result.date}-${result.amount}`} />;
      })}
      </Grid>
      <Grid verticalAlgin="center" width="100%" horizontalAlgin="center" margin="mt-2" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Pagination data={sanitizedFilter} handlerSetFilter={handlerSetFilter} data-sentry-element="Pagination" data-sentry-source-file="index.tsx" />
      </Grid>
    </>;
}