import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React from 'react';
interface NavLinkProps {
  uri: string;
  icon?: React.ReactNode;
  label: string;
  handleNavFunction: (uri: string) => void;
  hidden?: boolean;
  notification?: React.ReactNode;
}
export function NavLink({
  uri,
  icon,
  label,
  handleNavFunction,
  hidden = false,
  notification
}: NavLinkProps) {
  return <Grid gap="1rem" verticalAlgin="center" horizontalAlgin="flex-start" style={{
    cursor: 'pointer',
    color: '#fff'
  }} onClick={() => handleNavFunction(uri)} hidden={hidden} className="md:pl-10 xs:pl-20" data-sentry-element="Grid" data-sentry-component="NavLink" data-sentry-source-file="navLink.tsx">
      {icon}
      <Typography translateGroup="global" translateKey={label} color="#fff" size="normal" weight={700} className="lowercase tracking-widest first-letter:uppercase" data-sentry-element="Typography" data-sentry-source-file="navLink.tsx" />
      {notification}
    </Grid>;
}