import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { ReactNode, useContext } from 'react';
import DialogContext from 'context/dialog';
import styles from './styles.module.scss';
import PrefixSelector from './prefixSelector';
interface Props {
  label: ReactNode;
  mobileNumber: string | number;
  mobilePrefix: string | number;
  updateField: (fieldKey: string, value: any) => void;
  readOnly?: boolean;
}
export default function PhoneNumberInput(props: Props) {
  const {
    label,
    readOnly,
    mobileNumber,
    mobilePrefix,
    updateField
  } = props;
  const {
    displayDialog,
    removeDialog
  } = useContext(DialogContext);
  function handleDisplayDialog() {
    displayDialog({
      dialogId: 'SELECT-PREFIX',
      content: <PrefixSelector prefix={`${mobilePrefix || ''}`} setPrefix={(newPrefix: string) => {
        updateField('mobilePrefix', newPrefix);
        removeDialog('SELECT-PREFIX');
      }} />
    });
  }
  return <Grid gap="0.5rem" data-sentry-element="Grid" data-sentry-component="PhoneNumberInput" data-sentry-source-file="index.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {typeof label === 'string' ? <Typography translateGroup="input-group-label" translateKey={label as string} weight={400} style={{
        transform: 'scale(1.25)',
        transformOrigin: 'left'
      }} /> : label}

      </Grid>
      <Grid data-read-only={readOnly} gap="0.5rem" className={styles['input-group-wrapper']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <input className={styles.prefix} type="tel" value={mobilePrefix} readOnly onClick={() => handleDisplayDialog()} onFocus={() => handleDisplayDialog()} />
        <input className={styles.number} type="tel" value={mobileNumber} onChange={({
        target
      }) => updateField('mobileNumber', target.value)} />
        <Button id="profile-update-submit" type="submit" color="yellow" disabled={!!readOnly} style={{
        width: '5rem',
        maxWidth: '80px',
        height: '2.25rem',
        maxHeight: '36px'
      }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <Typography translateGroup="global" translateKey="VAHVISTA" size="sm" weight={700} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
        </Button>
      </Grid>
    </Grid>;
}