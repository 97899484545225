import ActiveFreeSpinsCard from 'components/cards/bonus/activeFreeSpins';
import Grid from 'components/uiKit/grid';
import { useContext } from 'react';
import AcceptedFreeSpinsCard from 'components/cards/bonus/acceptedFreeSpins';
import AvailableFreeSpinsCard from 'components/cards/bonus/availableFreeSpins';
import { FreeSpinsContext } from '..';
export default function FreeSpinsList() {
  const {
    freeSpinsListFilter
  } = useContext(FreeSpinsContext);
  const list = freeSpinsListFilter || [];
  return <Grid gap="1rem" style={{
    color: 'var(--primary)'
  }} data-sentry-element="Grid" data-sentry-component="FreeSpinsList" data-sentry-source-file="index.tsx">
      {list?.map((item: any) => {
      return item.FEstatus === 'active' ? <ActiveFreeSpinsCard key={`ActiveFreeSpins-${item.id}`} background={item.graphBackground} count={item.betCount} gameCode={item.gameCode} gameName={item.gameDisplayName || item.displayName} /> : item.FEstatus === 'accepted' ? <AcceptedFreeSpinsCard background={item?.bonusTemplate?.graphBackground} count={item?.bonusTemplate?.betCount} gameCode={item?.bonusTemplate?.gameCode} gameName={item?.bonusTemplate?.gameDisplayName || item?.bonusTemplate?.displayKeyword} freeSpinId={item.id} key={`Accepted-${item.id}`} /> : <AvailableFreeSpinsCard background={item.graphBackground} count={item.betCount} gameCode={item.gameCode} gameName={item.gameDisplayName || item.displayKeyword} freeSpinId={item.id} key={`AvailableFreeSpins-${item.id}`} />;
    })}
    </Grid>;
}