import Button from 'components/uiKit/buttons';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import { useEffect, useState } from 'react';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import { SizePage } from './sizePage';
interface PaginationProps {
  data?: Array<any>;
  handlerSetFilter: Function;
}
export function Pagination({
  data = [],
  handlerSetFilter
}: PaginationProps) {
  const [size, setSize] = useState<number>(10);
  const totalPages = Math.ceil(data.length / size);
  const arrayPages = Array.from({
    length: totalPages || 1
  }, (_, i) => i + 1);
  const [page, setPage] = useState<number>(1);
  function paginate(array: Array<any>, pageNumber: number, pageSize: number) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return array.slice(startIndex, endIndex);
  }
  function goToNextPage() {
    setPage(page + 1);
    handlerSetFilter(paginate(data, page, size));
  }
  function goToPreviousPage() {
    setPage(page - 1);
    handlerSetFilter(paginate(data, page, size));
  }
  function goSetPage(selectPage: number) {
    setPage(selectPage);
    handlerSetFilter(paginate(data, page, size));
  }
  useEffect(() => {
    handlerSetFilter(paginate(data, page, size));
  }, [size, data, page]);
  useEffect(() => {
    setPage(1);
  }, [data]);
  const viewOptionListPages = () => {
    if (page === 1) {
      return arrayPages.slice(page - 1, page + 4);
    }
    if (page - 3 < 0) {
      return arrayPages.slice(page - 2, page + 3);
    }
    if (page + 4 <= totalPages - 1) {
      return arrayPages.slice(page - 2, page + 3);
    }
    return arrayPages.slice(totalPages - 6, totalPages - 1);
  };
  const isEllipsis = viewOptionListPages()[viewOptionListPages().length - 1] < totalPages - 1;
  return <Grid gap="1rem" verticalAlgin="center" width="auto" data-sentry-element="Grid" data-sentry-component="Pagination" data-sentry-source-file="index.tsx">
      <MdOutlineNavigateBefore size="2rem" style={{
      backgroundColor: '#f1f1f1',
      borderRadius: '0.5rem',
      cursor: page > 1 ? 'pointer' : 'default'
    }} onClick={page > 1 ? () => goToPreviousPage() : undefined} data-sentry-element="MdOutlineNavigateBefore" data-sentry-source-file="index.tsx" />
      {arrayPages.length > 5 ? <>
          {viewOptionListPages().map((pg: number) => <Button id={`page-${pg}`} key={`page-${pg}`} style={{
        padding: 0,
        background: 'transparent',
        border: 'none'
      }} onClick={() => goSetPage(pg)}>
              <Typography style={{
          fontSize: '1rem',
          color: pg === page ? 'var(--text-color)' : 'darkgray',
          fontWeight: pg === page ? 'bold' : 'normal'
        }}>
                {pg}
              </Typography>
            </Button>)}
          {isEllipsis && <Typography style={{
        fontSize: '1rem',
        color: totalPages === page ? 'var(--text-color)' : 'darkgray',
        fontWeight: totalPages === page ? 'bold' : 'normal'
      }}>
              ...
            </Typography>}
          <Button id={`page-${totalPages}`} style={{
        padding: 0,
        background: 'transparent',
        border: 'none'
      }} onClick={() => goSetPage(totalPages)}>
            <Typography style={{
          fontSize: '1rem',
          color: totalPages === page ? 'var(--text-color)' : 'darkgray',
          fontWeight: totalPages === page ? 'bold' : 'normal'
        }}>
              {totalPages}
            </Typography>
          </Button>
        </> : <>
          {arrayPages.map((pg: number) => <Button id={`page-${pg}`} key={`page-${pg}`} style={{
        padding: 0,
        background: 'transparent',
        border: 'none'
      }} onClick={() => goSetPage(pg)}>
              <Typography style={{
          fontSize: '1rem',
          color: pg === page ? 'var(--text-color)' : 'darkgray',
          fontWeight: pg === page ? 'bold' : 'normal'
        }}>
                {pg}
              </Typography>
            </Button>)}
        </>}

      <MdOutlineNavigateNext size="2rem" style={{
      backgroundColor: '#f1f1f1',
      borderRadius: '0.5rem',
      cursor: totalPages > page ? 'pointer' : 'default'
    }} onClick={totalPages > page ? () => goToNextPage() : undefined} data-sentry-element="MdOutlineNavigateNext" data-sentry-source-file="index.tsx" />
      <SizePage size={size} setSize={setSize} data-sentry-element="SizePage" data-sentry-source-file="index.tsx" />
    </Grid>;
}