import { FC, ReactElement } from 'react';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
export interface MenuItemProps {
  className?: string;
  href?: string;
  isActive?: boolean;
  children?: ReactElement | string;
}
const defaultClassName = 'h-[6.2rem] flex items-center lg:p-5 md:pl-2.5 md:pr-2.5 hover:bg-white active:bg-yellow-active';
export const MenuItem: FC<MenuItemProps> = ({
  className,
  href,
  isActive,
  children
}) => <Link className={twMerge(defaultClassName, className, isActive ? 'border-b-2 border-[var(--primary)] bg-yellow-active' : '')} href={href ?? ''} key={href ?? ''} data-sentry-element="Link" data-sentry-component="MenuItem" data-sentry-source-file="MenuItem.tsx">
    {typeof children === 'string' ? <span className="text-primary text-[0.75rem] font-bold">{children}</span> : children}
  </Link>;