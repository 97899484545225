export function transformNameToFileName(name: string): string {
  return (
    `${name
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with hyphens
    }-logo.svg` // Append '-logo.svg' at the end
  )
}

// 3 suppliers are commented out because they are not supported
// if they are added make sure that they have a image in the public/assets/game-providers
export const ALLOWED_SUPPLIERS = [
  'Hacksaw Gaming',
  'Pragmatic Play',
  'Nolimit City',
  "Play'n Go",
  'NetEnt',
  'Relax Gaming',
  'Evolution Gaming',
  'Novomatic',
  'Big Time Gaming',
  'Red Tiger',
  'Thunderkick',
  'Quickspin',
  'Slotmill',
  'Spinomenal',
  'Blueprint Gaming',
  'Print Studios',
  'Endorphina',
  'Bullshark Games',
  'Backseat Gaming',
  'Raw iGaming',
  // 'Pragmatic Play Live',
  // 'Habanero',
  // 'Booming Games',
]
