// hook.js
import {
 useState, useEffect, useContext, ChangeEvent,
} from 'react'
import { useRouter } from 'next/router'
import GlobalContext from 'context/global'
import { MobileNavbarLoggedSections } from 'context/global/types'
import ProfileForm from 'sections/profileForm'
import HistorySection from 'sections/history'
import { BlockSelfSection } from 'sections/blocksSelf'
import { BlockDepositSection } from 'sections/blockDeposit'
import styles from './styles.module.scss'
import { menuMobileAnimation } from '../menuAnimation'
import { searchAnimation } from '../animationFade'

const SECTIONS_THAT_NO_BACK = ['nav', 'menu', 'search']

export const useLoggedMobileMenu = () => {
  const [filter, setFilter] = useState<string>('')
  const [isFixed, setIsFixed] = useState<boolean>(false)
  const [stateProfile, setStateProfile] = useState('PROFILE')
  const { section, setSection } = useContext(GlobalContext)
  const { asPath } = useRouter()

  const isSectionIncludeInList = SECTIONS_THAT_NO_BACK.includes(section)

  const MENUPROFILE = [
    { key: 'PROFILE', Component: ProfileForm },
    { key: 'HISTORY', Component: HistorySection },
    { key: 'BLOCK_SELF', Component: BlockSelfSection },
    { key: 'BLOCK_DEPOSIT', Component: BlockDepositSection },
  ]

  const translateTitles: { [key in MobileNavbarLoggedSections]: string } = {
    'deposit-section': 'Talletus',
    'menu-profile': 'Tilini',
    campaigns: 'Kampanjat',
    login: 'Kirjaudu sisään',
    menu: 'Valikko',
    nav: 'Navigointi',
    rewards: 'Palkinnot',
    search: 'Haku',
  }

  useEffect(() => {
    if (section !== 'nav') {
      document.body.classList.add(styles.noScroll)
    } else {
      document.body.classList.remove(styles.noScroll)
    }
    return () => {
      document.body.classList.remove(styles.noScroll)
    }
  }, [section])

  useEffect(() => {
    setSection('nav')
  }, [asPath])

  useEffect(() => {
    const mobileMenu: HTMLElement | null = document.getElementById('mobile-menu')
    const checkScroll = () => {
      if (mobileMenu?.scrollTop && mobileMenu.scrollTop > 0) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    if (mobileMenu) {
      mobileMenu.addEventListener('scroll', checkScroll)
    }
    return () => {
      mobileMenu?.removeEventListener('scroll', checkScroll)
    }
  }, [])

  useEffect(() => {
    const mobileMenu: HTMLElement | null = document.getElementById('mobile-menu')
    const mobileInputFilter: HTMLElement | null = document.getElementById('search-input-container')

    if (mobileMenu) {
      if (section === 'nav') {
        menuMobileAnimation({ state: 'collapse', menu: mobileMenu })
      } else {
        menuMobileAnimation({ state: 'expand', menu: mobileMenu })
      }
    }

    if (mobileInputFilter) {
      if (section === 'search') {
        searchAnimation({ state: 'start', element: mobileInputFilter })
      } else {
        searchAnimation({ state: 'finish', element: mobileInputFilter })
      }
    }
  }, [section])

  const handlerHamburger = () => {
    setSection((current: MobileNavbarLoggedSections) => (current === 'nav' ? 'menu' : 'nav'))
    setFilter('')
  }

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  return {
    filter,
    isFixed,
    isSectionIncludeInList,
    handlerHamburger,
    handleFilterChange,
    MENUPROFILE,
    stateProfile,
    setStateProfile,
    translateTitles,
    section,
    setSection,
  }
}
