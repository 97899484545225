import ExpandCollapseCard from 'components/cards/expandCollapseCard';
import { SectionProps } from 'components/navbar/mobile/loggedIn';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import ProfileForm from 'layouts/profile/sections/tabs/profile/content/profile';
import React from 'react';
export default function ProfileSection({
  state,
  handleState
}: SectionProps) {
  return <ExpandCollapseCard color="white" value={state} header={<Grid horizontalAlgin="center" onClick={handleState}>
          <Typography translateGroup="profile-form" translateKey="TIEDOT" weight={600} />
        </Grid>} data-sentry-element="ExpandCollapseCard" data-sentry-component="ProfileSection" data-sentry-source-file="index.tsx">
      <ProfileForm data-sentry-element="ProfileForm" data-sentry-source-file="index.tsx" />
    </ExpandCollapseCard>;
}