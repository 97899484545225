import Grid from 'components/uiKit/grid';
import { ChangeEvent, useCallback } from 'react';
import styles from './styles.module.scss';
interface SizePageProps {
  size: number;
  setSize: Function;
}
export function SizePage({
  size,
  setSize
}: SizePageProps) {
  const handlerSize = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSize(parseInt(event.target.value, 10));
  }, []);
  return <Grid width="3.5rem" verticalAlgin="center" className={styles.content} data-sentry-element="Grid" data-sentry-component="SizePage" data-sentry-source-file="sizePage.tsx">
      <input type="number" name="page" id="page" value={size} onChange={handlerSize} />
    </Grid>;
}