import Typography from 'components/uiKit/typography';
import { accountInterface } from 'utils/globalTypes/users';
export const errorMessages = () => ({
  login: {
    required: <Typography translateGroup="form-validate-messages-login" translateKey="required" />
  },
  firstName: {
    required: <Typography translateGroup="form-validate-messages-firstName" translateKey="required" />
  },
  lastName: {
    required: <Typography translateGroup="form-validate-messages-lastName" translateKey="required" />
  },
  address: {
    required: <Typography translateGroup="form-validate-messages-address" translateKey="required" />
  },
  state: {
    required: <Typography translateGroup="form-validate-messages-state" translateKey="required" />
  },
  countryCode: {
    required: <Typography translateGroup="form-validate-messages-countryCode" translateKey="required" />
  },
  city: {
    required: <Typography translateGroup="form-validate-messages-city" translateKey="required" />
  },
  postCode: {
    required: <Typography translateGroup="form-validate-messages-postCode" translateKey="required" />
  },
  mobileNumber: {
    invalid: <Typography translateGroup="form-validate-messages-mobileNumber" translateKey="invalid" />
  }
});
function validateForm(data: accountInterface) {
  const erros: any = {};
  if (!data.firstName) {
    erros.firstName = errorMessages().firstName.required;
  }
  if (!data.userProfileDTO.address1) {
    erros.address1 = errorMessages().address.required;
  }
  if (!data.userProfileDTO.countryCode || data.userProfileDTO.countryCode === 'XX') {
    erros.countryCode = errorMessages().countryCode.required;
  }
  if (!data.userProfileDTO.city) {
    erros.city = errorMessages().city.required;
  }
  return {
    ...erros,
    count: Object.keys(erros).length
  };
}
export default validateForm;