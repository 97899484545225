import React from 'react';
import styles from './styles.module.scss';
interface HamburgerMenuIconProps {
  isOpen: boolean;
  onClick?: () => void;
}
function HamburgerMenuIcon({
  isOpen,
  onClick
}: HamburgerMenuIconProps) {
  return <div onClick={onClick} className={styles.container} data-sentry-component="HamburgerMenuIcon" data-sentry-source-file="index.tsx">
      <div className={styles['hamburger-icon']} data-is-open={isOpen}>
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </div>;
}
export default HamburgerMenuIcon;