import ExpandCollapseCard from 'components/cards/expandCollapseCard';
import Card from 'components/uiKit/card';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React from 'react';
export default function LimitsSection() {
  return <ExpandCollapseCard color="white" header={<Grid horizontalAlgin="center">
                    <Typography translateGroup="limits-section" translateKey="LIMITS" weight={600} />
                </Grid>} data-sentry-element="ExpandCollapseCard" data-sentry-component="LimitsSection" data-sentry-source-file="index.tsx">
            ...
        </ExpandCollapseCard>;
}