import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React from 'react';
export default function Disclaimer() {
  return <Grid gap="1rem" data-sentry-element="Grid" data-sentry-component="Disclaimer" data-sentry-source-file="disclaimer.tsx">
            <Grid data-sentry-element="Grid" data-sentry-source-file="disclaimer.tsx">
                <Typography translateGroup="xp-bonus-disclaimer" translateKey="title" weight={600} color="#fff" size="lg" data-sentry-element="Typography" data-sentry-source-file="disclaimer.tsx" />
            </Grid>
            <Grid data-sentry-element="Grid" data-sentry-source-file="disclaimer.tsx">
                <Typography translateGroup="xp-bonus-disclaimer" translateKey="content" color="#fff" data-sentry-element="Typography" data-sentry-source-file="disclaimer.tsx" />
            </Grid>
        </Grid>;
}