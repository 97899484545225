import gsap from 'gsap'

interface menuMobileAnimationProps {
  state: 'start'|'finish';
  element: HTMLElement;
}

export function searchAnimation({
  state,
  element,
}: menuMobileAnimationProps) {
  const durationMultiplier = 1.8 // Adjust duration as needed
  const bounceEase = 'elastic.out(1, 0.9)' // Adjust easing for bounce effect

  if (state === 'start') {
    gsap
      .timeline({
        defaults: { duration: durationMultiplier / 2, ease: 'power1.inOut' },
      })
      .to(element, {
        opacity: 1,
      })
  } else {
    gsap
      .timeline({
        defaults: { duration: durationMultiplier, ease: bounceEase },
      })
      .fromTo(
        element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      )
  }
}
