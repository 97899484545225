import React from 'react';
import { staticLobbyItem } from 'pages/api/static-lobbies';
import LargePromoGameCard from './variants/large';
import PromoGameCardDefault from './variants/default';
import LargePromoGameCard2 from './variants/large-2';
export default function PromoGameCard(props: staticLobbyItem) {
  const {
    size
  } = props;
  if (size === 2) {
    return <LargePromoGameCard {...props} />;
  }
  if (size === 3) {
    return <LargePromoGameCard2 {...props} />;
  }
  return <PromoGameCardDefault {...props} data-sentry-element="PromoGameCardDefault" data-sentry-component="PromoGameCard" data-sentry-source-file="index.tsx" />;
}