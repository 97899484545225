import CloseIcon2 from 'assets/icons/closeIcon2';
import SearchIcon from 'assets/icons/searchIcon';
import Grid from 'components/uiKit/grid';
import { textTranslated } from 'components/textTranslated';
import WrapperEditTranslation from 'components/textTranslated/wrapperEditTranslation';
import styles from './search.module.scss';
export function Search({
  handleFilterChange,
  filter,
  setFilter
}: any) {
  return <Grid verticalAlgin="center" horizontalAlgin="center" data-sentry-element="Grid" data-sentry-component="Search" data-sentry-source-file="search.tsx">
      <Grid id="search-input-container" verticalAlgin="center" className={styles['filter-input']} wrap="nowrap" horizontalAlgin="flex-end" gap="0.5rem" style={{
      padding: '0.6rem'
    }} data-focus={!!filter} data-sentry-element="Grid" data-sentry-source-file="search.tsx">
        {!filter && <SearchIcon width={16} height={16} />}
        <WrapperEditTranslation group="search" translateKey="search-key" data-sentry-element="WrapperEditTranslation" data-sentry-source-file="search.tsx">
          <input id="filter" name="filter" placeholder={textTranslated({
          group: 'search',
          key: 'search-key'
        })} style={{
          padding: 0
        }} value={filter} onChange={handleFilterChange} />
        </WrapperEditTranslation>
        {filter && <CloseIcon2 onClick={() => setFilter('')} style={{
        cursor: 'pointer'
      }} width="1rem" color="var(--primary)" height="1rem" />}
      </Grid>
    </Grid>;
}