import AuthContext from 'context/auth';
import { useContext } from 'react';
import MobileNavbarLoggedIn from './loggedIn';
import MobileNavbarLoggedOut from './loggedOut';
export default function NavbarMobile() {
  const {
    isAuthenticated
  } = useContext(AuthContext);
  if (isAuthenticated) {
    return <MobileNavbarLoggedIn />;
  }
  return <MobileNavbarLoggedOut data-sentry-element="MobileNavbarLoggedOut" data-sentry-component="NavbarMobile" data-sentry-source-file="index.tsx" />;
}