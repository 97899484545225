import React, { useEffect, useState } from 'react';
import Grid from '../grid';
import { gridProps } from '../grid/types';
import Body from './body';
import Header from './header';
import { Pagination } from './pagination';
export type dataTableColumnType = {
  label: string;
  key: string;
  uniqueId: string;
  filter?: boolean;
  style?: React.CSSProperties;
  render?: Function;
  avoidRowClick?: boolean;
  html?: boolean;
  parseFilter?: Function;
  filterExactMatch?: string;
  columnGridProps?: gridProps;
};
export const DataTableContext = React.createContext<DataTableContextInterface>({
  filter: null,
  setFilter: () => {},
  data: null,
  columns: null,
  pagination: false,
  selectedItem: null,
  handlerSetFilter: () => {},
  sortState: null,
  setSortState: () => {},
  originalData: null,
  page: 1,
  setPage: () => {},
  toggleSort: (value: any) => {},
  size: 10,
  setSize: () => {}
});
export interface DataTableContextInterface {
  filter: any;
  setFilter: Function;
  data: any;
  columns: null | Array<dataTableColumnType>;
  onRowClick?: Function;
  pagination?: boolean;
  selectedItem?: any;
  selectedId?: any;
  handlerSetFilter: Function;
  sortState: any;
  setSortState: Function;
  originalData: any;
  page: number;
  setPage: Function;
  toggleSort: Function;
  size: number;
  setSize: Function;
}
export function DataTable(props: any) {
  const {
    columns,
    data,
    onRowClick,
    pagination,
    selectedItem,
    selectedId
  } = props;
  const [dataFilterInput, setDataFilterInput] = useState(data);
  const [dataFilterOrder, setDataFilterOrder] = useState(data);
  const [sortState, setSortState] = React.useState<any>();
  const [filter, setFilter] = React.useState<any>();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState(10);
  function paginate(pageNumber: number, pageSize: number) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataFilterOrder.slice(startIndex, endIndex);
  }
  const handlerSetFilter = () => {
    setDataFilterInput(paginate(page, size));
  };
  function sortTransactions(transactions: any, field: string, order = 'asc') {
    return transactions.sort((a: any, b: any) => {
      if (field === 'date') {
        const dateA = new Date(a[field]);
        const dateB = new Date(b[field]);
        return order === 'asc' ? Number(dateA) - Number(dateB) : Number(dateB) - Number(dateA);
      }
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        const strA = a[field].toUpperCase();
        const strB = b[field].toUpperCase();
        if (strA < strB) {
          return order === 'asc' ? -1 : 1;
        }
        if (strA > strB) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      }
      return order === 'asc' ? a[field] - b[field] : b[field] - a[field];
    });
  }
  function toggleSort(key: string) {
    setPage(1);
    if (sortState?.column === key) {
      const newDirection = sortState.sort === 'asc' ? 'desc' : 'asc';
      setSortState((prevState: any) => ({
        ...prevState,
        sort: newDirection
      }));
    } else {
      setSortState({
        column: key,
        sort: 'asc'
      });
    }
  }
  useEffect(() => {
    if (sortState?.column) {
      const value = sortTransactions(data, sortState?.column, sortState?.sort);
      setDataFilterOrder(value);
    } else {
      setDataFilterOrder(data);
    }
    handlerSetFilter();
  }, [sortState, data]);
  return <DataTableContext.Provider value={{
    data: dataFilterInput,
    originalData: dataFilterOrder,
    columns,
    onRowClick,
    pagination,
    selectedItem,
    selectedId,
    handlerSetFilter,
    filter,
    setFilter,
    setSortState,
    sortState,
    page,
    setPage,
    toggleSort,
    size,
    setSize
  }} data-sentry-element="unknown" data-sentry-component="DataTable" data-sentry-source-file="index.tsx">
      <Header data-sentry-element="Header" data-sentry-source-file="index.tsx" />
      <Body data-sentry-element="Body" data-sentry-source-file="index.tsx" />
      <Grid verticalAlgin="center" width="100%" horizontalAlgin="center" margin="mt-2" padding={['pb-4']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Pagination data-sentry-element="Pagination" data-sentry-source-file="index.tsx" />
      </Grid>
    </DataTableContext.Provider>;
}